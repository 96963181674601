import React from "react";
import Reviews from "./Components/Reviews/Reviews";
import About from "./Components/About/About";
import Banner from "./Components/Banner/Banner";
import Buttons from "./Components/Buttons/Buttons";
import Contact from "./Components/Contact/Contact";
import Gallery from "./Components/Gallery/Gallery";
import CopyRight from "./Components/CopyRight/CopyRight";
import Map from "./Components/Map/Map";
import Video from "./Components/Video/Video";
import Afiliates from "./Components/Afiliates/Afiliates";
import Refferel from "./Components/Refferel/Refferel";
import Events from "./Components/Events/Events";

const HomepageMain = () => {
	return (
		<div className='bodyMain'>
			<Banner />
			<Buttons />
			<About />
			<Events />
			<Gallery />
			<Video />
			<Reviews />
			<Map />
			<Contact />
			<Refferel />
			<Afiliates />
			<CopyRight />
		</div>
	);
};

export default HomepageMain;
