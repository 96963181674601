import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
	useEffect(() => {
		AOS.init({
			once: false,
			mirror: true,
		});
	}, []);
	const { data } = useAPI();
	return (
		<Container sx={{ pt: 5 }} className='details'>
			{data?._id ? (
				<>
					{!data?.headline?.aboutUs === "off" ? (
						""
					) : (
						<>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Box className='z4'>
									<Typography
										className=' bgColor borderColor'
										sx={{
											mb: 1.5,
											fontWeight: 900,
											color: "white",
											px: 3,
											py: 0.5,
											borderRadius: "25px",
											border: "4px solid",
										}}
										variant='h4'
										component='div'
										gutterBottom>
										{data?.headline?.aboutUs}
									</Typography>
								</Box>
							</Box>
							<Grid container spacing={2}>
								<Grid item md={9} className='textColor ' sx={{ mx: "auto" }}>
									<Typography sx={{ whiteSpace: "pre-line" }}>
										{data?.details?.details}
									</Typography>
								</Grid>

								<Grid item md={7.5} xs={12} sx={{ mx: "auto", mt: 1, mb: -2 }}>
									<img
										src={data?.details?.logo}
										alt=''
										className='borderColor'
										style={{ width: "100%", border: "4px solid" }}
									/>
								</Grid>
							</Grid>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default About;

/* import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
	useEffect(() => {
		AOS.init({
			once: false,
			mirror: true,
		});
	}, []);
	const { data } = useAPI();
	return (
		<Container sx={{ pt: 5 }} className='details'>
			{data?._id ? (
				<>
					{!data?.headline?.aboutUs === "off" ? (
						""
					) : (
						<>
							<Grid container spacing={2}>
								<Grid item md={9} className='textColor ' sx={{ mx: "auto" }}>
									<Accordion>
										<AccordionSummary
											className='border borderColor'
											sx={{
												textAlign: "center",
												border: "4px solid",
											}}
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<Typography
												sx={{
													fontWeight: 900,
													borderRadius: "25px",
												}}
												variant='h5'>
												{data?.headline?.aboutUs}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography sx={{ whiteSpace: "pre-line" }}>
												{data?.details?.details}
											</Typography>
										</AccordionDetails>
									</Accordion>
								</Grid>

								<Grid item md={7.5} xs={12} sx={{ mx: "auto", mt: 1, mb: -2 }}>
									<img
										src={data?.details?.logo}
										alt=''
										className='borderColor'
										style={{ width: "100%", border: "4px solid" }}
									/>
								</Grid>
							</Grid>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default About;
 */
