import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import { styled } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Details = () => {
	const form = useRef();

	const [loading, setLoading] = useState(false);

	const Input = styled("input")({
		display: "none",
	});
	const [info, setInfo] = useState();
	const [imageLink2, setImageLink2] = useState(info?.logo);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "DBC-LOGO");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dbc-of-tony/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2({
			imageLink2: file.secure_url,
			public_id: file.public_id,
		});
		setLoading(false);
	};
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			details: "",
		},
	});
	const id = "62e6d54ef72dff2efb4be231";
	useEffect(() => {
		axios.get(`https://soldbytm.onrender.com/userDB/${id}`).then((res) => {
			reset(res.data?.details);
			setInfo(res.data?.details);
		});
	}, [reset]);
	const onSubmit = ({ details, video }) => {
		setSubmitting(true);
		const data = {
			details,
			logo: imageLink2?.imageLink2 || photo,
			public_id: imageLink2?.public_id,
			old_public_id: info?.public_id,
			submitTime: new Date().toLocaleString(),
		};
		axios
			.put(`https://soldbytm.onrender.com/details/${id}`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your details updated Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
				setPhoto(data?.logo);
				setImageLink2("");
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [photo, setPhoto] = useState(info?.logo);
	useEffect(() => {
		setPhoto(info?.logo);
	}, [info?.logo, submitting]);

	return (
		<Container sx={{ pt: 1 }} className='details'>
			<Typography
				variant='h4'
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Details
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>

			<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={3}>
					<Grid item md={12} sm={12} xs={12}>
						<TextField
							required
							name='Details'
							label='Details'
							fullWidth
							multiline
							rows={5}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("details", { required: true })}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<img src={photo} style={{ width: "300px" }} alt='' /> <br />
						{imageLink2?.imageLink2 && (
							<Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
								<ArrowDownwardIcon
									className='textColor'
									fontSize='large'
									sx={{ fontWeight: 900 }}
								/>
								<ArrowDownwardIcon
									className='textColor'
									fontSize='large'
									sx={{ fontWeight: 900 }}
								/>
								<ArrowDownwardIcon
									className='textColor'
									fontSize='large'
									sx={{ fontWeight: 900 }}
								/>
								<ArrowDownwardIcon
									className='textColor'
									fontSize='large'
									sx={{ fontWeight: 900 }}
								/>
							</Box>
						)}
						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ my: 1, mx: "auto" }}>
							{!imageLink2?.imageLink2 && (
								<label
									htmlFor='icon-button-file'
									className='bgColor'
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										margin: "0 9px",
										borderRadius: 5,
									}}>
									<Input
										accept='image/*'
										id='icon-button-file'
										type='file'
										onChange={uploadImage}
									/>
									<Typography
										sx={{ my: 2, ml: 2, color: "white" }}
										variant='body1'
										component='div'
										gutterBottom>
										Upload Your New Logo*
									</Typography>
									<IconButton
										sx={{ mx: 2 }}
										color='primary'
										aria-label='upload picture'
										component='span'>
										<AttachFileIcon
											fontSize='large'
											sx={{ fontWeight: "bold" }}
										/>
									</IconButton>
								</label>
							)}

							{loading ? (
								<Box sx={{ my: 2 }}>
									<CircularProgress className='textColor' />
								</Box>
							) : (
								<img
									src={imageLink2?.imageLink2}
									style={{ width: "300px" }}
									alt=''
								/>
							)}
						</Box>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								border: "4px solid",
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Update
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Details;
