import {
	Button,
	Card,
	CardMedia,
	CircularProgress,
	Container,
	Grid,
	Rating,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Review = () => {
	const { id } = useParams();
	const [data, setData] = useState([]);
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/review/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data[0]));
	}, [id]);

	return (
		<Container className='reviews'>
			{data?.userName ? (
				<Grid
					container
					spacing={0}
					direction='column'
					alignItems='center'
					justifyContent='center'
					style={{ minHeight: "85vh" }}>
					<Box sx={{ mx: "auto" }}>
						<Card
							className='borderColor'
							sx={{
								pb: 2,
								my: 1,
								minWidth: "300px",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								alignContent: "center",
								overflow: "visible",
								border: "4px solid ",
								borderRadius: 3,
							}}>
							<CardMedia
								component='img'
								className='borderColor'
								style={{
									marginTop: -70,
									borderRadius: "50%",
									width: "150px",
									height: "150px",
									border: "5px solid ",
									backgroundColor: "white",
								}}
								image={data?.userPhoto}
								alt=''
							/>
							<Rating
								sx={{ mt: 1.5 }}
								name='read-only'
								value={data?.rating + 0}
								readOnly
							/>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								className='textColor'
								sx={{ mt: 1, fontWeight: "bold" }}>
								{data?.userName}
							</Typography>

							<Typography variant='body2' sx={{ mt: 1, px: 1 }}>
								{data?.userReview}
							</Typography>
						</Card>
					</Box>
					<Link to='/dashboard/allreviews' style={{ textDecoration: "none" }}>
						<Button
							variant='contained'
							className='button border'
							sx={{
								mt: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
								backgroundColor: "transparent",
								border: "4px solid",
							}}>
							<KeyboardBackspaceIcon sx={{ mr: 1.5 }} />
							Back to List
						</Button>
					</Link>
				</Grid>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CircularProgress className='textColor' />
				</div>
			)}
		</Container>
	);
};

export default Review;
