import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import PropagateLoader from "react-spinners/PropagateLoader";
import Typewriter from "typewriter-effect";
import { useAPI } from "../../../../apiContext";
import SwiperCore, { EffectCube, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Wave } from "react-animated-text";
SwiperCore.use([Autoplay]);

const Banner = () => {
	const { data } = useAPI();
	return (
		<div className='banner'>
			{data?._id ? (
				<Card
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						boxShadow: 0,
						position: "relative",
						backgroundColor: "transparent",
					}}>
					<Box
						sx={{
							width: "100%",
							backgroundColor: "white",
							display: "flex",
							justifyContent: "center",
						}}>
						<CardMedia
							component='img'
							alt=''
							sx={{
								objectFit: "unset",
								width: "100%",
							}}
							image={"/headerLogo.png"}
						/>
					</Box>
					<Swiper
						loop={true}
						grabCursor={true}
						effect={"cube"}
						cubeEffect={{
							shadow: false,
							slideShadows: false,
							shadowOffset: 20,
							shadowScale: 0.94,
						}}
						modules={[EffectCube]}
						autoplay={{ delay: 2000 }}
						className='mySwiper'>
						{data?.banners?.map((banner, key) => (
							<SwiperSlide key={key} style={{ cursor: "grab" }}>
								<Box
									sx={{
										height: { xs: 150, sm: 230, md: 270 },
										border: "4px solid var(--color1st)",
									}}>
									<CardMedia
										className=''
										sx={{
											width: "100%",
											height: "100%",
											backgroundSize: "cover",
											objectFit: "fill",
										}}
										component='img'
										alt=''
										image={banner?.imageLink2}
									/>
								</Box>
							</SwiperSlide>
						))}
					</Swiper>

					<Box
						sx={{
							height: { md: 200, xs: 150 },
							width: { md: 200, xs: 150 },
							mt: { md: -11, xs: -7 },
							position: "relative",
							zIndex: 1,
						}}>
						<CardMedia
							className='profilePhoto '
							sx={{
								boxShadow: 11,
								borderRadius: "50%",
								width: "100%",
								height: "100%",
								border: "4px solid var(--color1st)",
							}}
							component='img'
							alt=''
							image={data?.profile?.imageLink2}
						/>
					</Box>
					<CardContent>
						<Typography
							className='textColor title'
							sx={{ fontWeight: 500, my: 1.9, fontSize: "2rem" }}
							variant='h4'
							component='div'>
							<Wave
								text={data?.headline?.title}
								effect='jump'
								effectChange={0.5}
								delay={2}
							/>
						</Typography>
						<Typography
							className='textColor'
							sx={{ fontWeight: 700 }}
							variant='h5'
							component='div'
							gutterBottom>
							<Typewriter
								options={{
									strings: [
										`${data?.headline?.subtitle || ""}`,
										`${data?.links?.call}`,
									],
									autoStart: true,
									loop: true,
								}}
							/>
						</Typography>
					</CardContent>
				</Card>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</div>
	);
};

export default Banner;
