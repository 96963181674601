import React, { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import { useAPI } from "../../../../apiContext";

const ShareButton = () => {
	const { data } = useAPI();
	const [show, setShow] = useState(true);
	const url = window.location.pathname;
	useEffect(() => {
		if (url.includes("/dashboard" || "/login")) {
			setShow(false);
		}
	}, [url]);
	return (
		<div className='share'>
			{show && (
				<RWebShare
					data={{
						text: `${data?.headline?.subtitle}`,
						url: `${window.location.origin.toString()}`,
						title: `${data?.headline?.title}`,
					}}
					onClick={() => console.log("shared successfully!")}>
					<IconButton
						style={{
							position: "fixed",
							bottom: "9px",
							right: "9px",
							border: "4px solid",
						}}>
						<ShareIcon sx={{ fontSize: 35 }} />
					</IconButton>
				</RWebShare>
			)}
		</div>
	);
};

export default ShareButton;
