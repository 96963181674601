import {
	Button,
	Card,
	CircularProgress,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Mail = () => {
	const { id } = useParams();
	const [data, setData] = useState([]);
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/emails/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data[0]));
	}, [id]);

	return (
		<Container className='mails'>
			{data?.userName ? (
				<Grid
					container
					spacing={0}
					direction='column'
					alignItems='center'
					justifyContent='center'
					style={{ minHeight: "85vh" }}>
					<Box>
						<Card
							className='borderColor'
							sx={{
								p: 2,
								my: 1,
								minWidth: "300px",
								display: "flex",
								flexDirection: "column",
								alignContent: "center",
								overflow: "visible",
								border: "4px solid ",
								textAlign: "left",
								borderRadius: 3,
							}}>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								className='textColor'
								sx={{ mt: 1 }}>
								Hello,
							</Typography>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								className='textColor'
								sx={{ mt: 1 }}>
								<b>Name : </b>
								{data?.userName}
							</Typography>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								className='textColor'
								sx={{ mt: 1 }}>
								<b>E-mail : </b>
								{data?.userEmail}
							</Typography>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								className='textColor'
								sx={{ mt: 1 }}>
								<b>Phone : </b>
								{data?.phoneNumber || "N/A"}
							</Typography>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								className='textColor'
								sx={{ mt: 1 }}>
								<b>Rec Time : </b>
								{data?.submitTime}
							</Typography>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								className='textColor'
								sx={{ mt: 1 }}>
								<b>Subject : </b>
								{data?.subject}
							</Typography>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								className='textColor'
								sx={{ mt: 1 }}>
								<b>Message : </b>
								{data?.message}
							</Typography>
						</Card>
					</Box>
					<Link to='/dashboard/allemails' style={{ textDecoration: "none" }}>
						<Button
							variant='contained'
							className='button border'
							sx={{
								mt: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
								backgroundColor: "transparent",
								border: "4px solid",
							}}>
							<KeyboardBackspaceIcon sx={{ mr: 1.5 }} />
							Back to List
						</Button>
					</Link>
				</Grid>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CircularProgress className='textColor' />
				</div>
			)}
		</Container>
	);
};

export default Mail;
