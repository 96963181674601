import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
	apiKey: "AIzaSyB-FfZy3IGc075PfuG_DnblNKG3pt3x02o",
	authDomain: "soldbytm777.firebaseapp.com",
	projectId: "soldbytm777",
	storageBucket: "soldbytm777.appspot.com",
	messagingSenderId: "221424016040",
	appId: "1:221424016040:web:c13f86dd5fffd18cf2809d",
	measurementId: "G-BYZTZWTQ6X",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
