import { Button, CardMedia, Container, Rating } from "@mui/material";
import React from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import PropagateLoader from "react-spinners/PropagateLoader";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AddReview from "./AddReview";
import { useAPI } from "../../../../apiContext";
SwiperCore.use([Autoplay, Pagination]);

const Reviews = () => {
	const { data } = useAPI();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);

	return (
		<Container
			sx={{ pt: 5, "& .swiper-slide": { height: "auto" }, cursor: "grab" }}
			className='reviews'>
			{data?._id ? (
				<>
					{data?.headline?.review === "off" ? (
						""
					) : (
						<>
							<Box className='z2'>
								<Typography
									className=' bgColor borderColor'
									sx={{
										mb: 1.5,
										fontWeight: 900,
										color: "white",
										px: 3,
										py: 0.5,
										borderRadius: "25px",
										border: "4px solid",
									}}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.review}
								</Typography>
							</Box>
							<Swiper
								loop={true}
								autoplay={{ delay: 2000 }}
								grabCursor={true}
								slidesPerView={3}
								spaceBetween={30}
								pagination={{
									clickable: true,
								}}
								breakpoints={{
									300: {
										slidesPerView: 1,
									},
									550: {
										slidesPerView: 2,
									},
									900: {
										slidesPerView: 3,
									},
								}}
								className='mySwiper'>
								{data?.reviews?.map((review, key) => (
									<SwiperSlide key={key}>
										<Box>
											<Card
												className='borderColor'
												sx={{
													mt: 5,
													mx: 1.5,
													mb: 1,
													pb: 2,
													bgcolor: "transparent",
													minHeight: "200px",
													maxHeight: "270px",
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													alignContent: "center",
													overflow: "visible",
													border: "4px solid ",
													borderRadius: "25px",
												}}>
												<CardMedia
													component='img'
													className='borderColor'
													style={{
														marginTop: -35,
														borderRadius: "50%",
														width: "80px",
														height: "80px",
														border: "5px solid ",
														backgroundColor: "white",
													}}
													image={review?.userPhoto}
													alt=''
												/>
												<Rating
													sx={{ mt: 1.5 }}
													name='read-only'
													value={review?.rating}
													readOnly
												/>
												<Typography
													gutterBottom
													variant='h6'
													component='div'
													className='textColor'
													sx={{ mt: 1, fontWeight: "bold" }}>
													{review?.userName}
												</Typography>

												<Typography
													className='textColor'
													variant='body2'
													sx={{ mt: 1, px: 1 }}>
													{review?.userReview}
												</Typography>
											</Card>
										</Box>
									</SwiperSlide>
								))}
							</Swiper>
							<Box className='icons z4'>
								<Button
									onClick={handleOpen}
									variant='contained'
									sx={{
										mt: 2,
										px: 3,
										py: 1.7,
										width: "90%",
										fontWeight: "bold",
										borderRadius: "25px",
										border: "4px solid ",
									}}>
									<RateReviewIcon sx={{ mr: 1.5 }} />
									Leave Feedback​
								</Button>
							</Box>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}

			{open && <AddReview open={open} setOpen={setOpen} />}
		</Container>
	);
};
export default Reviews;
