import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const AllReviews = () => {
	const id = "62e6d54ef72dff2efb4be231";
	const [reviews, setReviews] = useState();
	const [deleted, setDeleted] = useState(false);
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/${id}`)
			.then((res) => res.json())
			.then((data) => setReviews(data?.reviews?.reverse()));
	}, [deleted]);

	const handleDelete = (deletedReview) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(
						`https://soldbytm.onrender.com/deleteReview/${id}/${deletedReview?.reviewId}`,
						{
							public_id: deletedReview?.public_id,
						},
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That Review has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	let count = 1;
	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }} className='reviews'>
			<Grid>
				<Typography
					variant='h4'
					className='textColor'
					sx={{ fontWeight: "bold" }}>
					All Reviews
				</Typography>
				<Typography sx={{ pb: 2 }} className='textColor'>
					Last changed in {reviews?.[0]?.submitTime || "N/A"}
				</Typography>
				<Grid item xs={12} md={12}>
					<Paper
						className='container'
						sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
						<Table size='small' aria-label='a dense table'>
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>No</TableCell>
									<TableCell align='left'>Photo</TableCell>
									<TableCell align='left'>Name</TableCell>
									<TableCell align='left'>Rec Time</TableCell>
									{/* <TableCell align='left'>Rating</TableCell>
									<TableCell align='left'>Reviews</TableCell> */}
									<TableCell align='center'>Action</TableCell>
								</TableRow>
							</TableHead>
							{reviews?.length > 0 ? (
								<TableBody sx={{ td: { py: 1 } }}>
									{reviews.map((review, key) => (
										<TableRow
											key={key}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											<TableCell align='left'>{count++}</TableCell>
											<TableCell>
												<img
													src={review?.userPhoto || "N/A"}
													alt=''
													width='50px'
													height='50px'
													style={{ borderRadius: "50%" }}
												/>
											</TableCell>
											<TableCell align='left'>
												{review?.userName || "N/A"}
											</TableCell>
											<TableCell align='left'>
												{review?.submitTime || "N/A"}
											</TableCell>
											{/*<TableCell align='left'>{review?.rating}</TableCell>
											 <TableCell align='left'>
												{review?.userReview || "N/A"}
											</TableCell> */}
											<TableCell align='center'>
												<Link
													to={`/dashboard/allreviews/${review?.reviewId}`}
													style={{ textDecoration: "none" }}>
													<Button
														className='button border'
														sx={{
															fontWeight: "bold",
															border: "4px solid",
															backgroundColor: "transparent",
															borderRadius: "25px",
															m: 0.5,
														}}
														variant='contained'>
														<RemoveRedEyeIcon />
													</Button>
												</Link>
												<Button
													className='button border'
													onClick={() => handleDelete(review)}
													sx={{
														fontWeight: "bold",
														border: "4px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
													}}
													variant='contained'>
													<DeleteIcon />
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										{/*<TableCell align='left'>N/A</TableCell>
										 <TableCell align='left'>N/A</TableCell> */}
										<TableCell align='left'>N/A</TableCell>
									</TableRow>
								</TableHead>
							)}
						</Table>
					</Paper>
				</Grid>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!reviews}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default AllReviews;
