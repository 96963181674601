import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Button,
	Chip,
	CircularProgress,
	Container,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";

const ColorChange = () => {
	const id = "62e6d54ef72dff2efb4be231";
	const [submitting, setSubmitting] = useState(false);

	const { handleSubmit, reset } = useForm({
		defaultValues: {
			color: "",
			colorborder: "",
		},
	});

	const [info, setInfo] = useState();
	useEffect(() => {
		axios.get(`https://soldbytm.onrender.com/userDB/${id}`).then((res) => {
			reset(res.data?.color);
			setInfo(res.data?.color);
		});
	}, [reset]);

	const [color, setColor] = useState(info?.color1st);
	const [bordercolor, setborderColor] = useState(info?.colorborder);
	const onSubmit = () => {
		setSubmitting(true);
		const data = {
			type: "color",
			color1st: color || info?.color1st,
			colorborder: bordercolor || info?.colorborder,
			submitTime: new Date().toLocaleString(),
		};
		axios
			.put(`https://soldbytm.onrender.com/color/${id}`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }} className='colorChange'>
			<Typography
				variant='h4'
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Change Color And Border Style
			</Typography>
			<Typography variant='body' gutterBottom className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					spacing={3}
					display='flex'
					flexDirection='column'
					alignContent='center'
					sx={{ mt: 5 }}>
					<Grid item md={8} sm={8} xs={12}>
						<Chip label='Click below to change color' sx={{ mb: 1 }} />
						<input
							style={{ width: "100%", height: "57px" }}
							type='color'
							value={color || info?.color1st}
							onChange={(e) => setColor(e.target.value)}
						/>
						<input
							style={{ width: "100%", height: "45px", padding: "0 1rem" }}
							type='text'
							value={color || info?.color1st}
							onChange={(e) => setColor(e.target.value)}
						/>
					</Grid>
					<Grid item md={8} sm={8} xs={12}>
						<Chip label='Click below to change Border color' sx={{ mb: 1 }} />
						<input
							style={{ width: "100%", height: "57px" }}
							type='color'
							value={bordercolor || info?.colorborder}
							onChange={(e) => setborderColor(e.target.value)}
						/>
						<input
							style={{ width: "100%", height: "45px", padding: "0 1rem" }}
							type='text'
							value={bordercolor || info?.colorborder}
							onChange={(e) => setborderColor(e.target.value)}
						/>
					</Grid>

					<Grid item md={8} sm={8} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								border: "4px solid",
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ColorChange;
