import React, { useState } from "react";

const UseData = () => {
	const id = "62e6d54ef72dff2efb4be231";
	const [data, setData] = useState();
	React.useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	return [data];
};

export default UseData;
