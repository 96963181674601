import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axios from "axios";

const AllEventById = () => {
	const { id } = useParams();
	const [data, setData] = useState();
	const [deleted, setDeleted] = useState(false);
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/EventsRegsById/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data?.reverse()));
	}, [id, deleted]);

	const handleDelete = (deletedEvent) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`https://soldbytm.onrender.com/EventsRegs/${deletedEvent?._id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That Event has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	return (
		<div>
			{data?.map((dt, index) => (
				<Card variant='outlined' sx={{ m: 1 }} key={index}>
					<CardContent>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<strong>User:- {index + 1}</strong>
							<DeleteIcon sx={{ ml: 1.5 }} onClick={() => handleDelete(dt)} />
						</Box>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Typography variant='body2'>
									<strong>Name:</strong> {dt?.userName}{" "}
									<ClickToCopy text={dt?.userName} />
								</Typography>
								<Typography variant='body2'>
									<strong>Address:</strong> {dt?.address}{" "}
									<ClickToCopy text={dt?.address} />
								</Typography>
								<Typography variant='body2'>
									<strong>City:</strong> {dt?.city}{" "}
									<ClickToCopy text={dt?.city} />
								</Typography>
								<Typography variant='body2'>
									<strong>ZIP Code:</strong> {dt?.zip}{" "}
									<ClickToCopy text={dt?.zip} />
								</Typography>
								<Typography variant='body2'>
									<strong>State:</strong> {dt?.state}{" "}
									<ClickToCopy text={dt?.state} />
								</Typography>
								<Typography variant='body2'>
									<strong>Phone:</strong> {dt?.phoneNumber}{" "}
									<ClickToCopy text={dt?.phoneNumber} />
								</Typography>
								<Typography variant='body2'>
									<strong>Email:</strong> {dt?.userEmail}{" "}
									<ClickToCopy text={dt?.userEmail} />
								</Typography>
								<Typography variant='body2'>
									<strong>Time:</strong> {dt?.time}{" "}
									<ClickToCopy text={dt?.time} />
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography variant='body2'>
									<strong>Real Estate Needs:</strong>
								</Typography>
								<Typography variant='body2'>
									<strong>Looking to Buy:</strong>{" "}
									{dt?.lookingToBuy ? "Yes" : "No"}{" "}
									<ClickToCopy text={dt?.lookingToBuy ? "Yes" : "No"} />
								</Typography>
								<Typography variant='body2'>
									<strong>Planning to Buy:</strong>{" "}
									{dt?.planningToBuy ? "Yes" : "No"}{" "}
									<ClickToCopy text={dt?.planningToBuy ? "Yes" : "No"} />
								</Typography>
								<Typography variant='body2'>
									<strong>Looking to Sell:</strong>{" "}
									{dt?.lookingToSell ? "Yes" : "No"}{" "}
									<ClickToCopy text={dt?.lookingToSell ? "Yes" : "No"} />
								</Typography>
								<Typography variant='body2'>
									<strong>Just Shopping:</strong>{" "}
									{dt?.justShopping ? "Yes" : "No"}{" "}
									<ClickToCopy text={dt?.justShopping ? "Yes" : "No"} />
								</Typography>
								<Typography variant='body2'>
									<strong>Event ID:</strong> {dt?.eventId}{" "}
									<ClickToCopy text={dt?.eventId} />
								</Typography>
								<Typography variant='body2'>
									<strong>Submitted on:</strong> {dt?.submitTime}{" "}
									<ClickToCopy text={dt?.submitTime} />
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			))}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 999,
				}}
				open={!data}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default AllEventById;

const ClickToCopy = ({ text }) => {
	const [isCopied, setIsCopied] = useState(false);

	const handleCopy = () => {
		setIsCopied(true);
		// You can also show a notification or perform other actions upon copying.
	};

	return (
		<CopyToClipboard text={text} onCopy={handleCopy}>
			<Tooltip title={isCopied ? "Copied!" : "Click to Copy"} arrow>
				<IconButton
					sx={{
						p: "1px",
						ml: 1,
					}}
					size='small'
					aria-label='Copy'>
					<FileCopyIcon fontSize='small' />
				</IconButton>
			</Tooltip>
		</CopyToClipboard>
	);
};
