import { Box, Container, Typography } from "@mui/material";
import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";

const Map = () => {
	const { data } = useAPI();
	return (
		<Container sx={{ pt: 5 }}>
			{data?._id && data?.headline?.findUs ? (
				<>
					{data?.headline?.findUs === "off" ? (
						""
					) : (
						<>
							<Box className='z1'>
								<Typography
									className=' bgColor borderColor'
									sx={{
										mb: 1.5,
										fontWeight: 900,
										color: "white",
										px: 3,
										py: 0.5,
										borderRadius: "25px",
										border: "4px solid",
									}}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.findUs}
								</Typography>
							</Box>
							<Box sx={{ height: { md: "70vh", xs: "50vh" } }}>
								<iframe
									src={`${data?.map?.map}`}
									title={"Google map"}
									style={{
										width: "100%",
										height: "100%",
										border: "4px solid",
									}}
									className='borderColor'
									allowFullScreen=''
									loading='lazy'></iframe>
							</Box>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Map;
