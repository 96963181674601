import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./Gallery.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";
SwiperCore.use([Autoplay, Pagination]);

const Gallery = () => {
	const { data } = useAPI();

	return (
		<Container
			sx={{ pt: 5, "& .swiper-slide": { height: "auto" }, cursor: "grab" }}>
			{data?._id && data?.headline?.gallery ? (
				<>
					{data?.headline?.gallery === "off" ? (
						""
					) : (
						<>
							<Box className='z1'>
								<Typography
									className=' bgColor borderColor'
									sx={{
										mb: 1.5,
										fontWeight: 900,
										color: "white",
										px: 3,
										py: 0.5,
										borderRadius: "25px",
										border: "4px solid",
									}}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.gallery}
								</Typography>
							</Box>
							<Swiper
								loop={true}
								autoplay={{ delay: 2000 }}
								grabCursor={true}
								slidesPerView={3}
								spaceBetween={30}
								pagination={{
									clickable: true,
								}}
								breakpoints={{
									300: {
										slidesPerView: 1,
									},
									550: {
										slidesPerView: 2,
									},
									900: {
										slidesPerView: 3,
									},
								}}
								className='mySwiper'>
								{data?.gallery?.map((a, key) => (
									<SwiperSlide key={key}>
										<Box>
											<Card
												className='borderColor'
												sx={{
													mx: 1.5,
													mb: 1,
													pb: 2,
													bgcolor: "transparent",
													overflow: "visible",
													boxShadow: "none",
												}}>
												<CardMedia
													component='img'
													className='borderColor'
													style={{
														border: "4px solid",
														width: "100%",
													}}
													image={a?.imageLink2}
													alt=''
												/>
											</Card>
										</Box>
									</SwiperSlide>
								))}
							</Swiper>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Gallery;
