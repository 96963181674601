import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Box,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";

const Contact = () => {
	const { data } = useAPI();
	const form = useRef();
	const id = "62e6d54ef72dff2efb4be231";
	const { register, handleSubmit, reset } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const onSubmit = ({ userName, userEmail, phoneNumber, subject, message }) => {
		const data = {
			mailId: `mail${Math.floor(100000 + Math.random() * 900000)}`,
			userName,
			userEmail,
			phoneNumber,
			subject,
			message,
			submitTime: new Date().toLocaleString(),
		};
		setSubmitting(true);
		axios
			.put(`https://soldbytm.onrender.com/emails/${id}`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your Mail Sent Successfully",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const [num, setNum] = useState();
	return (
		<Container sx={{ pt: 5 }} className='contact'>
			{data?._id ? (
				<>
					{!data?.headline?.contact === "off" ? (
						""
					) : (
						<>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Box className='z4'>
									<Typography
										className=' bgColor borderColor'
										sx={{
											mb: 1.5,
											fontWeight: 900,
											color: "white",
											px: 3,
											py: 0.5,
											borderRadius: "25px",
											border: "4px solid",
										}}
										variant='h4'
										component='div'
										gutterBottom>
										{data?.headline?.contact}
									</Typography>
								</Box>
							</Box>
							<Typography>
								<Grid container spacing={2}>
									<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
										<form
											ref={form}
											onSubmit={handleSubmit(onSubmit)}
											method='post'>
											<Grid container spacing={2}>
												<Grid item md={6} xs={12}>
													<Grid container spacing={2}>
														<Grid item md={12} xs={12}>
															<TextField
																required
																sx={{ width: "100%" }}
																id='outlined-basic'
																name='UserName'
																label='Full Name'
																placeholder='Ex: John Doe'
																type='text'
																inputProps={{
																	minLength: 5,
																}}
																{...register("userName", {
																	required: true,
																})}
															/>
														</Grid>
														<Grid item md={12} xs={12}>
															<TextField
																required
																sx={{ width: "100%" }}
																id='outlined-basic'
																name='UserEmail'
																type='email'
																label='E-mail'
																placeholder='Ex: john@email.com'
																inputProps={{
																	pattern:
																		"[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
																}}
																{...register("userEmail", {
																	required: true,
																})}
															/>
														</Grid>
														<Grid item md={12} xs={12}>
															<TextField
																required
																sx={{ width: "100%" }}
																id='outlined-basic'
																name='PhoneNumber'
																type='text'
																label='Phone Number'
																inputProps={{
																	minLength: 10,
																}}
																placeholder='Ex: (310) 777-7777'
																value={num}
																onInput={(e) =>
																	setNum(e.target.value.replace(/[^0-9]/g, ""))
																}
																{...register("phoneNumber", { required: true })}
															/>
														</Grid>
														<Grid item md={12} xs={12}>
															<TextField
																required
																sx={{ width: "100%", mb: { md: 2, xs: 0 } }}
																id='outlined-basic'
																label='Subject'
																name='Subject'
																type='text'
																inputProps={{
																	minLength: 5,
																}}
																placeholder='If you want to buy or sell properly '
																{...register("subject", { required: true })}
															/>
														</Grid>
													</Grid>
												</Grid>

												<Grid item md={6} xs={12}>
													<TextField
														required
														sx={{ width: "100%", mb: 2 }}
														id='"outlined-multiline-flexible'
														label='Message'
														name='Message'
														placeholder='Please write your message here'
														multiline
														rows={10.2}
														type='text'
														inputProps={{
															minLength: 15,
														}}
														{...register("message", { required: true })}
													/>
												</Grid>
											</Grid>
											<Box className='icons z1'>
												<Button
													className='borderColor'
													type='submit'
													variant='contained'
													sx={{
														px: 3,
														py: 0.5,
														width: "100%",
														fontWeight: "bold",
														borderRadius: "25px",
														border: "4px solid ",
														fontSize: "1rem",
													}}>
													Submit
												</Button>
											</Box>
										</form>
									</Grid>
								</Grid>
							</Typography>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Contact;
