import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const AllRefferels = () => {
	const [deleted, setDeleted] = useState(false);
	const [refferels, setRefferels] = useState();
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/refferels`)
			.then((res) => res.json())
			.then((data) => setRefferels(data?.reverse()));
	}, [deleted]);
	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`https://soldbytm.onrender.com/refferel/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That Referral has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	let count = 1;
	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }} className='mails'>
			<Grid>
				<Typography
					variant='h4'
					className='textColor'
					sx={{ fontWeight: "bold" }}>
					All Referrals
				</Typography>
				<Typography sx={{ pb: 2 }} className='textColor'>
					Last changed in {refferels?.[0]?.submitTime || "N/A"}
				</Typography>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Reffered By</TableCell>
								<TableCell align='left'>Rec Time</TableCell>
								{/*	<TableCell align='left'>Email</TableCell>
							 	<TableCell align='left'>Phone Number</TableCell>
								<TableCell align='left'>Subject</TableCell>
								<TableCell align='left'>Details</TableCell> */}
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{refferels?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{refferels?.map((refferel, key) => (
									<TableRow
										key={key}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>
											{refferel?.refByuserName}
										</TableCell>
										<TableCell align='left'>{refferel?.submitTime}</TableCell>
										{/* 	<TableCell align='left'>
											{mail?.userEmail || "N/A"}
										</TableCell>
										<TableCell align='left'>
											{mail?.phoneNumber || "N/A"}
										</TableCell>
										<TableCell align='left'>{mail?.subject}</TableCell>
										<TableCell align='left'>{mail?.message}</TableCell> */}
										<TableCell align='center'>
											<Link
												to={`/dashboard/refferels/${refferel?._id}`}
												style={{ textDecoration: "none" }}>
												<Button
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "4px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<RemoveRedEyeIcon />
												</Button>
											</Link>
											<Button
												className='button border'
												onClick={() => handleDelete(refferel?._id)}
												sx={{
													fontWeight: "bold",
													border: "4px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									{/* 		<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell> */}
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!refferels}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default AllRefferels;
