import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import "swiper/css";
import "../Gallery/Gallery.css";
import { useAPI } from "../../../../apiContext";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const AllEvents = () => {
	const { data } = useAPI();
	const [datas, setDatas] = React.useState();
	React.useEffect(() => {
		fetch(`https://soldbytm.onrender.com/AllEvents`)
			.then((res) => res.json())
			.then((data) => setDatas(data?.reverse()));
	}, []);
	return (
		<Container sx={{ py: 1.5 }}>
			{datas && data?.headline?.events ? (
				<>
					{data?.headline?.events === "off" ? (
						""
					) : (
						<>
							<Box className='z3'>
								<Typography
									className=' bgColor borderColor'
									sx={{
										mb: 1.5,
										fontWeight: 900,
										color: "white",
										px: 3,
										py: 0.5,
										borderRadius: "25px",
										border: "4px solid",
									}}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.events}
								</Typography>
							</Box>
							<Grid
								container
								spacing={2}
								sx={{
									justifyContent: "center",
								}}>
								{datas?.map((a, key) => (
									<Grid key={key} item xs={12} sm={6} md={4}>
										<Box>
											{a?.default === "video" ? (
												<ReactPlayer
													key={a?.videoLink}
													className='borderColor react-player'
													width='100%'
													url={a?.videoLink}
													controls
												/>
											) : (
												<img style={{ width: "100%" }} src={a?.image} alt='' />
											)}
										</Box>
										<Box sx={{ mx: "auto", pt: "3px !important" }}>
											<Typography
												className='textColor'
												sx={{ fontWeight: 900 }}
												variant='h6'
												component='div'>
												{a?.title || "N/A"}
											</Typography>
											<Typography
												className='textColor'
												variant='p'
												component='div'>
												{a?.date || "N/A"}
											</Typography>
											<Typography
												className='textColor'
												variant='p'
												component='div'>
												{a?.time || "N/A"}
											</Typography>
											<Typography
												className='textColor'
												variant='p'
												component='div'>
												At {`${a?.address}, ${a?.city}, ${a?.state}, ${a?.zip}`}
											</Typography>
											<Link to={`/event/${a?._id}`}>
												<Button
													className='borderColor bgColor'
													variant='contained'
													sx={{
														mt: 1,
														px: 3,
														py: 0.5,
														fontWeight: "bold",
														borderRadius: "25px",
														border: "2px solid ",
													}}>
													Click Here to Register
												</Button>
											</Link>
										</Box>
									</Grid>
								))}
							</Grid>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default AllEvents;
