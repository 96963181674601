import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AllMails from "./Pages/Dashboard/AllMails/AllMails";
import AllReviews from "./Pages/Dashboard/AllReviews/AllReviews";
import BannerPhoto from "./Pages/Dashboard/BannerPhoto/BannerPhoto";
import ButtonsLink from "./Pages/Dashboard/ButtonsLink/ButtonsLink";
import ColorChange from "./Pages/Dashboard/ColorChange/ColorChange";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Details from "./Pages/Dashboard/Details/Details";
import Galleryphoto from "./Pages/Dashboard/Galleryphoto/Galleryphoto";
import Headline from "./Pages/Dashboard/Headline/Headline";
import ProfilePhoto from "./Pages/Dashboard/ProfilePhoto/ProfilePhoto";
import HomepageMain from "./Pages/Homepage/HomepageMain";
import HashLoader from "react-spinners/HashLoader";
import Login from "./Pages/Login/Login";
import AdminRoute from "./AdminRoute/AdminRoute";
import Review from "./Pages/Dashboard/AllReviews/Review";
import Mail from "./Pages/Dashboard/AllMails/Mail";
import ShareButton from "./Pages/Homepage/Components/ShareButton/ShareButton";
import Bg from "./Pages/Dashboard/Bg/Bg";
import useData from "./useData";
import { APIContextProvider } from "./apiContext";
import AddVideo from "./Pages/Dashboard/AddVideo/AddVideo";
import ManageMap from "./Pages/Dashboard/ManageMap/ManageMap";
import Afiliates from "./Pages/Homepage/Components/Afiliates/Afiliates";
import AfiliatesManage from "./Pages/Dashboard/AfiliatesManage/AfiliatesManage";
import AllRefferels from "./Pages/Dashboard/AllRefferels/AllRefferels";
import Refferels from "./Pages/Dashboard/AllRefferels/Refferels";
import { useEffect } from "react";
import Event from "./Pages/Homepage/Components/Events/Event";
import EventsManage from "./Pages/Dashboard/EventsManage/EventsManage";
import AllEventById from "./Pages/Dashboard/EventsManage/AllEventById";
import AllEvents from "./Pages/Homepage/Components/Events/AllEvents";

const RELOAD_TIMEOUT = 10000; // 10 seconds

function App() {
	const [datas, setDatas] = useState(null);

	useEffect(() => {
		const fetchData = () => {
			fetch(`https://soldbytm.onrender.com/version`)
				.then((res) => res.json())
				.then((responseData) => {
					setDatas(responseData);
				})
				.catch((error) => {
					console.error("Fetch error:", error);
				});
		};

		// Call the fetchData function initially
		fetchData();
	}, []); // Empty dependency array ensures this effect runs only once for data fetching

	useEffect(() => {
		let timerId;

		// Set a timer to refresh the page if data is not received within 3 seconds
		timerId = setTimeout(() => {
			window.location.reload();
		}, 3000);

		// Clear the timer when data is received
		if (datas) {
			clearTimeout(timerId);
		}

		// Cleanup: Clear the timer when the component unmounts
		return () => {
			clearTimeout(timerId);
		};
	}, [datas]);

	useEffect(() => {
		const pingServer = async () => {
			try {
				const response = await fetch(`https://soldbytm.onrender.com/version`);
				if (response.ok) {
					console.log("Server is awake!");
				} else {
					console.warn("Server is not responding as expected.");
				}
			} catch (error) {
				console.error("Error pinging server:", error);
			}
		};

		const pingInterval = setInterval(pingServer, RELOAD_TIMEOUT);

		// Clear the interval when the component unmounts
		return () => clearInterval(pingInterval);
	}, []);

	const [data] = useData();
	document.documentElement.style.setProperty(
		"--color1st",
		`${data?.color?.color1st}`,
	);
	document.documentElement.style.setProperty(
		"--colorborder",
		`${data?.color?.colorborder}`,
	);

	return (
		<div
			className='App'
			style={
				data?.bg?.bgColor === "off"
					? { backgroundImage: `url(${data?.bg?.imageLink2})` }
					: { backgroundColor: `${data?.bg?.bgColor}` }
			}>
			{data?._id ? (
				<APIContextProvider>
					<ShareButton />
					<BrowserRouter>
						<Routes>
							<Route path='/' element={<HomepageMain />} />
							<Route path='/login' element={<Login />} />
							<Route path='/AllEvents' element={<AllEvents />} />
							<Route path='/event/:id' element={<Event />} />
							<Route
								exact
								path='/dashboard'
								element={
									<AdminRoute>
										<Dashboard />
									</AdminRoute>
								}>
								<Route path='/dashboard' element={<BannerPhoto />} />
								<Route
									path='/dashboard/profilephoto'
									element={<ProfilePhoto />}
								/>
								<Route path='/dashboard/headline' element={<Headline />} />
								<Route
									path='/dashboard/buttonslink'
									element={<ButtonsLink />}
								/>
								<Route
									path='/dashboard/colorchange'
									element={<ColorChange />}
								/>
								<Route path='/dashboard/details' element={<Details />} />
								<Route
									path='/dashboard/galleryphoto'
									element={<Galleryphoto />}
								/>
								<Route path='/dashboard/managemap' element={<ManageMap />} />
								<Route path='/dashboard/bg' element={<Bg />} />
								<Route path='/dashboard/addvideo' element={<AddVideo />} />
								<Route path='/dashboard/allreviews/:id' element={<Review />} />
								<Route
									path='/dashboard/allafiliates'
									element={<AfiliatesManage />}
								/>
								<Route
									path='/dashboard/EventsManage'
									element={<EventsManage />}
								/>
								<Route
									path='/dashboard/EventsManage'
									element={<EventsManage />}
								/>
								<Route
									path='/dashboard/EventsManage/:id'
									element={<AllEventById />}
								/>
								<Route
									path='/dashboard/allafiliate/:id'
									element={<Afiliates />}
								/>
								<Route path='/dashboard/allreviews' element={<AllReviews />} />
								<Route path='/dashboard/allemails/:id' element={<Mail />} />
								<Route path='/dashboard/allemails' element={<AllMails />} />
								<Route
									path='/dashboard/refferels/:id'
									element={<Refferels />}
								/>
								<Route path='/dashboard/refferels' element={<AllRefferels />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</APIContextProvider>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<HashLoader size={100} color={"#35D5B6"} />
				</div>
			)}
		</div>
	);
}

export default App;
