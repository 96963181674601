import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";
import PropagateLoader from "react-spinners/PropagateLoader";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../Gallery/Gallery.css";
import { useAPI } from "../../../../apiContext";
SwiperCore.use([Autoplay, Pagination]);

const Video = () => {
	const { data } = useAPI();

	return (
		<Container
			sx={{ pt: 5, "& .swiper-slide": { height: "auto" }, cursor: "grab" }}>
			{data?._id && data?.headline?.video ? (
				<>
					{data?.headline?.video === "off" ? (
						""
					) : (
						<>
							<Box className='z3'>
								<Typography
									className=' bgColor borderColor'
									sx={{
										mb: 1.5,
										fontWeight: 900,
										color: "white",
										px: 3,
										py: 0.5,
										borderRadius: "25px",
										border: "4px solid",
									}}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.video}
								</Typography>
							</Box>
							<Swiper
								loop={true}
								autoplay={{ delay: 5000 }}
								grabCursor={true}
								slidesPerView={3}
								spaceBetween={30}
								pagination={{
									clickable: true,
								}}
								breakpoints={{
									300: {
										slidesPerView: 1,
									},
									550: {
										slidesPerView: 1,
									},
									900: {
										slidesPerView: 2,
									},
								}}
								className='mySwiper'>
								{data?.videos?.map((a, key) => (
									<SwiperSlide key={key}>
										<Grid container spacing={2}>
											<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
												<ReactPlayer
													key={a?.videoLink}
													className='borderColor react-player'
													width='100%'
													url={a?.videoLink}
													controls
												/>
											</Grid>
											<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
												<Typography
													className='textColor'
													sx={{ fontWeight: 900 }}
													variant='h6'
													component='div'>
													{a?.videoTitle}
												</Typography>
											</Grid>
										</Grid>
									</SwiperSlide>
								))}
							</Swiper>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Video;
