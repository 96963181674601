import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";

const ButtonsLink = () => {
	const [submitting, setSubmitting] = useState(false);
	const id = "62e6d54ef72dff2efb4be231";
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			email: "",
			facebook: "",
			whatsapp: "",
			youtube: "",
			sms: "",
			twitter: "",
			instagram: "",
			call: "",
			pinterest: "",
			linkedin: "",
			web1: "",
			web2: "",
		},
	});
	const [info, setInfo] = useState();
	useEffect(() => {
		axios.get(`https://soldbytm.onrender.com/userDB/${id}`).then((res) => {
			reset(res.data?.links);
			setInfo(res.data?.links);
		});
	}, [reset]);
	const onSubmit = ({
		email,
		facebook,
		whatsapp,
		youtube,
		sms,
		twitter,
		call,
		pinterest,
		linkedin,
		instagram,
		web1,
		web2,
	}) => {
		setSubmitting(true);
		const data = {
			type: "links",
			email,
			facebook,
			whatsapp,
			youtube,
			sms,
			twitter,
			instagram,
			call,
			pinterest,
			linkedin,
			web1,
			web2,
			submitTime: new Date().toLocaleString(),
		};
		axios
			.put(`https://soldbytm.onrender.com/links/${id}`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your links Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }} className='buttonsLink'>
			<Typography
				variant='h4'
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Buttons links
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='web1'
							label='Website 1'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("web1", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='web2'
							label='Website 2'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("web2", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Facebook'
							label='Facebook'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("facebook", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Email'
							label='Email'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='WhatsApp'
							label='WhatsApp'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("whatsapp", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Youtube'
							label='Youtube'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("youtube", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='call'
							label='Call'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("call", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='SMS'
							label='SMS'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("sms", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Twitter'
							label='Twitter'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("twitter", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Instagram'
							label='Instagram'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("instagram", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='linkedin'
							label='Linkedin'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("linkedin", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='pinterest'
							label='Pinterest'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("pinterest", { required: true })}
						/>
					</Grid>

					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								border: "4px solid",
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ButtonsLink;
