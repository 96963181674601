import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	IconButton,
	Rating,
	Backdrop,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styled from "@emotion/styled";
import StarIcon from "@mui/icons-material/Star";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	maxWidth: "85vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const AddReview = ({ open, setOpen }) => {
	const id = "62e6d54ef72dff2efb4be231";
	const [data, setData] = useState();
	React.useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, [open]);
	const form = useRef();
	const [ratings, setRatings] = React.useState();
	/* const [hover, setHover] = React.useState(-1); */
	const [submitting, setSubmitting] = useState(false);
	const [imageLink2, setImageLink2] = useState(null);
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ userName, userReview, email }) => {
		const data = {
			reviewId: `review${Math.floor(100000 + Math.random() * 900000)}`,
			userName,
			userReview,
			email,
			userPhoto: imageLink2?.imageLink2 || "/user.jpg",
			public_id: imageLink2?.public_id,
			rating: ratings,
			submitTime: new Date().toLocaleString(),
		};
		setSubmitting(true);

		axios
			.put(`https://soldbytm.onrender.com/review/${id}`, data)
			.then(function (response) {
				setOpen(false);
				setSubmitting(false);
				reset();
				Swal.fire({
					icon: "success",
					title: "Your Review Successfully Added",
					showConfirmButton: true,
					timer: 950000,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "DBC-Comment");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dbc-of-tony/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2({
			imageLink2: file.secure_url,
			public_id: file.public_id,
		});
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	return (
		<div className='reviews'>
			{data?.bg?.imageLink2 ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box
							sx={style}
							style={{ backgroundImage: `url(${data?.bg?.imageLink2})` }}>
							<CancelIcon
								onClick={() => setOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Typography
								className=' bgColor borderColor'
								sx={{
									mb: 1.5,
									fontWeight: 900,
									color: "white",
									px: 3,
									py: 0.5,
									borderRadius: "25px",
									border: "4px solid",
								}}
								variant='h4'
								component='div'
								gutterBottom>
								Leave Feedback
							</Typography>

							<Grid container spacing={2}>
								<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
									<form ref={form} onSubmit={handleSubmit(onSubmit)}>
										<Box
											display='flex'
											flexDirection='column'
											alignItems='center'
											sx={{ mt: 3, mb: 1, mx: "auto" }}>
											<label
												className='bgColor borderColor'
												htmlFor='icon-button-file'
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													margin: "0 9px",
													cursor: "pointer",
													minWidth: "280px",
													px: 3,
													p: 1.5,
													border: "4px solid",
													borderRadius: "25px",
												}}>
												<Input
													accept='image/*'
													id='icon-button-file'
													type='file'
													onChange={uploadImage}
												/>
												<Typography
													sx={{ my: 2, ml: 2, color: "white" }}
													variant='button'
													component='div'
													gutterBottom>
													Your Photo (Optional)
												</Typography>
												<IconButton
													sx={{ mx: 2 }}
													aria-label='upload picture'
													component='span'>
													<AccountCircleIcon
														fontSize='large'
														sx={{ fontWeight: "bold", color: "white" }}
													/>
												</IconButton>
											</label>

											{loading ? (
												<Box sx={{ my: 2 }}>
													<CircularProgress className='textColor' />
												</Box>
											) : (
												<img
													src={imageLink2?.imageLink2}
													style={{ width: "200px", borderRadius: "50%" }}
													alt=''
												/>
											)}
										</Box>
										<Box
											sx={{
												mt: 3,
												display: "flex",
												justifyContent: "center",
											}}>
											<Rating
												className='color-theme'
												sx={{
													float: "left",
													mb: 2,
													fontSize: 60,
												}}
												name='hover-feedback'
												value={ratings}
												size='large'
												precision={0.5}
												onChange={(event, newValue) => {
													setRatings(newValue);
												}}
												onChangeActive={(event, newHover) => {
													/* setHover(newHover); */
												}}
												emptyIcon={<StarIcon fontSize='inherit' />}
											/>
											{/* <Typography
										className='color-theme'
										variant='h4'
										component='div'
										sx={{ ml: 2, mb: 1.7 }}>
										{hover !== -1 ? hover : value}
									</Typography> */}
										</Box>
										{ratings && (
											<>
												<input
													type='text'
													value={ratings}
													hidden
													{...register("ratings", { required: true })}
												/>
												<TextField
													required
													sx={{ width: "100%", mb: 2 }}
													id='"outlined-multiline-flexible'
													label='Name'
													inputProps={{
														minLength: 5,
													}}
													{...register("userName", { required: true })}
												/>
												<TextField
													required
													sx={{ width: "100%", mb: 2 }}
													id='"outlined-multiline-flexible'
													label='E-mail'
													type='email'
													inputProps={{
														pattern:
															"[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
													}}
													{...register("email", { required: true })}
												/>
												<TextField
													required
													sx={{ width: "100%", mb: 2 }}
													id='"outlined-multiline-flexible'
													label='Share Your Review'
													multiline
													rows={4}
													inputProps={{
														minLength: 10,
													}}
													{...register("userReview", { required: true })}
												/>
												<Box className='icons z1'>
													<Button
														type='submit'
														variant='contained'
														sx={{
															px: 3,
															py: 1.5,
															width: "100%",
															fontWeight: "bold",
															borderRadius: "25px",
															border: "4px solid ",
														}}>
														Post Review
													</Button>
												</Box>
											</>
										)}
									</form>
								</Grid>
							</Grid>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default AddReview;
