import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import AddEvent from "./AddEvent";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import EditEvent from "./EditEvent";

const EventsManage = () => {
	const [events, setEvents] = useState();
	const [deleted, setDeleted] = useState(false);
	const [open, setOpen] = useState(false);
	const [edit, setEdit] = useState(false);
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/AllEvents`)
			.then((res) => res.json())
			.then((data) => setEvents(data?.reverse()));
	}, [deleted, open, edit]);
	const [EventsRegs, setEventsRegs] = useState();
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/EventsRegs`)
			.then((res) => res.json())
			.then((data) => setEventsRegs(data?.reverse()));
	}, [deleted, open]);

	const CountEventsRegs = (id) => {
		const count = EventsRegs?.filter((event) => event?.eventId === id);
		return count?.length;
	};

	const handleDelete = (deletedEvent) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`https://soldbytm.onrender.com/Events/${deletedEvent?._id}`, {
						public_id: deletedEvent?.public_id,
					})
					.then(function (response) {
						Swal.fire("Deleted!", "That Event has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	let count = 1;
	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }} className='reviews'>
			{open && <AddEvent open={open} setOpen={setOpen} />}
			{edit && <EditEvent open={edit} setOpen={setEdit} />}
			<Grid>
				<Typography
					variant='h4'
					className='textColor'
					sx={{ fontWeight: "bold" }}>
					All Events
				</Typography>
				<Button
					onClick={() => setOpen(true)}
					variant='contained'
					sx={{
						mb: 1,
						px: 3,
						py: 1.5,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "2px solid ",
					}}>
					Post New Event
				</Button>
				<Grid item xs={12} md={12}>
					<Paper
						className='container'
						sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
						<Table size='small' aria-label='a dense table'>
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>No</TableCell>
									<TableCell align='left'>Title</TableCell>
									<TableCell align='left'>Total Submissions</TableCell>
									<TableCell align='left'>Posted Time</TableCell>
									<TableCell align='center'>Action</TableCell>
								</TableRow>
							</TableHead>
							{events?.length > 0 ? (
								<TableBody sx={{ td: { py: 1 } }}>
									{events.map((event, key) => (
										<TableRow
											key={key}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											<TableCell align='left'>{count++}</TableCell>
											<TableCell align='left'>
												{event?.title || "N/A"}
											</TableCell>
											<TableCell align='left'>
												{CountEventsRegs(event?.eventId) || 0}
											</TableCell>

											<TableCell align='left'>
												{event?.submitTime || "N/A"}
											</TableCell>
											<TableCell align='center'>
												<Link to={`/dashboard/EventsManage/${event?.eventId}`}>
													<Button
														className='button border'
														sx={{
															fontWeight: "bold",
															border: "2px solid",
															backgroundColor: "transparent",
															borderRadius: "25px",
														}}
														variant='contained'>
														View Submissions
													</Button>
												</Link>

												<Button
													className='button border'
													onClick={() => setEdit(event)}
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														mx: 1,
													}}
													variant='contained'>
													<EditIcon />
												</Button>
												<Button
													className='button border'
													onClick={() => handleDelete(event)}
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
													}}
													variant='contained'>
													<DeleteIcon />
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
									</TableRow>
								</TableHead>
							)}
						</Table>
					</Paper>
				</Grid>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!events}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default EventsManage;
