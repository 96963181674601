import React, { useContext, useState, createContext, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getIdToken, onAuthStateChanged } from "firebase/auth";
import auth from "./Firebase/firebase.config";

const APIContext = createContext();

export function APIContextProvider({ children }) {
	const id = "62e6d54ef72dff2efb4be231";
	const [data, setData] = useState();
	React.useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	const [token, setToken] = useState("");
	const [user, loading] = useAuthState(auth);
	useEffect(() => {
		const unSubscribed = onAuthStateChanged(auth, (user) => {
			if (user) {
				getIdToken(user).then((idToken) => setToken(idToken));
			}
		});
		return () => unSubscribed;
	}, []);
	return (
		<APIContext.Provider
			value={{
				data: data,
				user: user,
				loading: loading,
				token: token,
			}}>
			{children}
		</APIContext.Provider>
	);
}

export function useAPI() {
	const context = useContext(APIContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
