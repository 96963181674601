import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Box,
	IconButton,
	Button,
	CircularProgress,
	Container,
	Grid,
	Chip,
} from "@mui/material";
import Swal from "sweetalert2";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";

import { styled } from "@mui/material/styles";

const Bg = () => {
	const id = "62e6d54ef72dff2efb4be231";
	const [submitting, setSubmitting] = useState(false);
	const [imageLink2, setImageLink2] = useState(null);

	const Input = styled("input")({
		display: "none",
	});

	const [bg, setBg] = React.useState();
	const [color, setColor] = useState(bg?.bgColor);
	React.useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/${id}`)
			.then((res) => res.json())
			.then((data) => setBg(data?.bg));
	}, []);
	const [photo, setPhoto] = useState(bg?.imageLink2);
	React.useEffect(() => {
		setPhoto(bg?.imageLink2);
	}, [bg?.imageLink2, submitting]);
	const bgphoto = {
		submitTime: new Date().toLocaleString(),
		bgColor: color || bg?.bgColor,
		type: "bg",
		imageLink2: imageLink2?.imageLink2 || photo,
		public_id: imageLink2?.public_id,
		old_public_id: bg?.public_id,
	};
	const update = () => {
		setSubmitting(true);
		axios
			.put(`https://soldbytm.onrender.com/bg/${id}`, bgphoto)
			.then(function (response) {
				setSubmitting(false);
				setImageLink2(null);
				Swal.fire({
					icon: "success",
					title: "Your card background updated Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
				setPhoto(bgphoto?.imageLink2);
				setImageLink2("");
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "DBC-BG");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dbc-of-tony/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2({
			imageLink2: file.secure_url,
			public_id: file.public_id,
		});
		setLoading(false);
	};

	return (
		<Container sx={{ pt: 1 }} className='bg'>
			<Typography
				variant='h4'
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Update Background Picture
			</Typography>
			<Typography variant='body' gutterBottom className='textColor'>
				Last changed in {bg?.submitTime || "N/A"}
			</Typography>
			<Grid
				container
				spacing={0}
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{ minHeight: "70vh" }}>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					sx={{ my: 2, mx: "auto" }}>
					{!imageLink2?.imageLink2 && (
						<label
							className='bgColor'
							htmlFor='icon-button-file'
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								margin: "0 9px",
								borderRadius: 5,
							}}>
							<Input
								accept='image/*'
								id='icon-button-file'
								type='file'
								onChange={uploadImage}
							/>
							<Typography
								sx={{ my: 2, ml: 2, color: "white" }}
								variant='body1'
								component='div'
								gutterBottom>
								Upload New Background Picture*
							</Typography>
							<IconButton
								sx={{ mx: 2 }}
								color='primary'
								aria-label='upload picture'
								component='span'>
								<AttachFileIcon fontSize='large' sx={{ fontWeight: "bold" }} />
							</IconButton>
						</label>
					)}

					{loading ? (
						<Box sx={{ my: 2 }}>
							<CircularProgress className='textColor' />
						</Box>
					) : (
						<>
							{imageLink2?.imageLink2 && (
								<img
									src={imageLink2?.imageLink2}
									style={{ width: "300px", height: "300px" }}
									alt=''
								/>
							)}
						</>
					)}
				</Box>
				<Box>
					<Chip
						className='bgColor'
						label='Click below to change color'
						sx={{ mb: 1 }}
					/>
					<input
						style={{ width: "100%", height: "57px" }}
						type='color'
						value={color || bg?.bgColor}
						onChange={(e) => setColor(e.target.value)}
					/>
					<input
						style={{ width: "100%", height: "45px", padding: "0 1rem" }}
						type='text'
						value={color || bg?.bgColor}
						onChange={(e) => setColor(e.target.value)}
					/>
					<Button
						onClick={update}
						variant='contained'
						sx={{
							width: "100%",
							my: 0.5,
							px: 3,
							border: "4px solid",
							fontWeight: "bold",
							borderRadius: "25px",
						}}>
						Save
					</Button>
				</Box>
				{imageLink2?.imageLink2 && (
					<Button
						onClick={update}
						variant='contained'
						component='span'
						className='button border'
						sx={{
							my: 1,
							py: 0.5,
							width: "150px",
							border: "4px solid",
							backgroundColor: "transparent",
						}}>
						Update
					</Button>
				)}
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !bg}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Bg;
