import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, Outlet } from "react-router-dom";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import PortraitIcon from "@mui/icons-material/Portrait";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FeedIcon from "@mui/icons-material/Feed";
import DialpadIcon from "@mui/icons-material/Dialpad";
import InfoIcon from "@mui/icons-material/Info";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Button } from "@mui/material";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import { useLocation } from "react-router-dom";
import auth from "../../Firebase/firebase.config";
import { signOut } from "firebase/auth";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import MapIcon from "@mui/icons-material/Map";
import HouseIcon from "@mui/icons-material/House";

const drawerWidth = 222;

function Dashboard(props) {
	const location = useLocation();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const drawer = (
		<Box sx={{ my: "auto" }} className='dashboard'>
			<List>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to=''>
					<ListItem
						button
						className={location?.pathname === "/dashboard" && "activeButton"}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<PhotoCameraBackIcon />
						</ListItemIcon>
						<ListItemText primary={"Banner Photo"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='profilephoto'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/profilephoto" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<PortraitIcon />
						</ListItemIcon>
						<ListItemText primary={"Profile Photo"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='bg'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/bg" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<WallpaperIcon />
						</ListItemIcon>
						<ListItemText primary={"BG Photo"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='galleryphoto'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/galleryphoto" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ViewCarouselIcon />
						</ListItemIcon>
						<ListItemText primary={"Gallery Photos"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='colorchange'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/colorchange" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ColorLensIcon />
						</ListItemIcon>
						<ListItemText primary={"Color & Border"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='headline'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/headline" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<FeedIcon />
						</ListItemIcon>
						<ListItemText primary={"Headline"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='buttonslink'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/buttonslink" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<DialpadIcon />
						</ListItemIcon>
						<ListItemText primary={"Buttons Link"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='details'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/details" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<InfoIcon />
						</ListItemIcon>
						<ListItemText primary={"Details"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='managemap'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/managemap" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<MapIcon />
						</ListItemIcon>
						<ListItemText primary={"Manage Map"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='addvideo'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/addvideo" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<OndemandVideoIcon />
						</ListItemIcon>
						<ListItemText primary={"Manage Videos"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allafiliates'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/allafiliates" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<OndemandVideoIcon />
						</ListItemIcon>
						<ListItemText primary={"Manage Afiliates"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='refferels'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/refferels" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ReviewsIcon />
						</ListItemIcon>
						<ListItemText primary={"All Refferels"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allreviews'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/allreviews" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ReviewsIcon />
						</ListItemIcon>
						<ListItemText primary={"All Reviews"} />
					</ListItem>
				</Link>

				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allemails'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/allemails" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ContactMailIcon />
						</ListItemIcon>
						<ListItemText primary={"Contact Forms"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='EventsManage'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/EventsManage" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<HouseIcon />
						</ListItemIcon>
						<ListItemText primary={"Events Manage"} />
					</ListItem>
				</Link>
			</List>
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;
	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position='fixed'
				className='bgColor'
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					padding: 0,
					ml: { sm: `${drawerWidth}px` },
				}}>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: "none" } }}>
						<MenuIcon />
					</IconButton>
					<Box display='flex' sx={{ flexGrow: 1 }}>
						<DashboardIcon sx={{ mr: 1 }} />
						<Typography variant='h6'>DASHBOARD</Typography>
					</Box>
					<Button
						onClick={() => signOut(auth)}
						color='inherit'
						className='logout'>
						LogOut
					</Button>
				</Toolbar>
			</AppBar>
			<Box
				onClick={() => setMobileOpen(false)}
				component='nav'
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
				aria-label='mailbox folders'>
				<Drawer
					container={container}
					variant='temporary'
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					className='dashboard'
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
						backgroundColor: "transparent",
					}}>
					{drawer}
				</Drawer>
				<Drawer
					variant='permanent'
					className='dashboard'
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
						backgroundColor: "transparent",
					}}
					open>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}>
				<Toolbar />
				<Outlet></Outlet>
			</Box>
		</Box>
	);
}

Dashboard.propTypes = {
	window: PropTypes.func,
};

export default Dashboard;
