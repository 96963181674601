import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	IconButton,
	Backdrop,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	maxWidth: "85vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const AddAfiliates = ({ open, setOpen }) => {
	const id = "62e6d54ef72dff2efb4be231";
	const [data, setData] = useState();
	React.useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, [open]);
	const form = useRef();
	const [submitting, setSubmitting] = useState(false);
	const [imageLink2, setImageLink2] = useState(null);
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ title, description, url }) => {
		const data = {
			afiliateId: `afiliate${Math.floor(100000 + Math.random() * 900000)}`,
			logo: imageLink2?.imageLink2 || "/user.jpg",
			public_id: imageLink2?.public_id,
			title,
			description,
			url,
			submitTime: new Date().toLocaleString(),
		};
		setSubmitting(true);

		axios
			.put(`https://soldbytm.onrender.com/afiliate/${id}`, data)
			.then(function (response) {
				setSubmitting(false);
				reset();
				setOpen(false);
				Swal.fire({
					icon: "success",
					title: "Your Afiliate Successfully Added",
					showConfirmButton: true,
					timer: 950000,
				}).then(function () {
					reset();
					setOpen(false);
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "DBC-Afiliate");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dbc-of-tony/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2({
			imageLink2: file.secure_url,
			public_id: file.public_id,
		});
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	return (
		<div className='reviews'>
			{data?.bg?.imageLink2 ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box
							sx={style}
							style={{ backgroundImage: `url(${data?.bg?.imageLink2})` }}>
							<CancelIcon
								onClick={() => setOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Typography
								className=' bgColor'
								sx={{
									mb: 1.5,
									fontWeight: 900,
									color: "white",
									px: 3,
									py: 0.5,
									borderRadius: "25px",
									border: "4px solid",
								}}
								variant='h4'
								component='div'
								gutterBottom>
								Add Afiliate
							</Typography>

							<Grid container spacing={2}>
								<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
									<form ref={form} onSubmit={handleSubmit(onSubmit)}>
										<Box
											display='flex'
											flexDirection='column'
											alignItems='center'
											sx={{ mt: 3, mb: 1, mx: "auto" }}>
											<label
												className='bgColor'
												htmlFor='icon-button-file'
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													margin: "0 9px",
													cursor: "pointer",
													minWidth: "280px",
													px: 3,
													p: 1.5,
													border: "4px solid white",
													borderRadius: "25px",
												}}>
												<Input
													accept='image/*'
													id='icon-button-file'
													type='file'
													onChange={uploadImage}
												/>
												<Typography
													sx={{ my: 2, ml: 2, color: "white" }}
													variant='button'
													component='div'
													gutterBottom>
													Upload Logo
												</Typography>
												<IconButton
													sx={{ mx: 2 }}
													aria-label='upload picture'
													component='span'>
													<AccountCircleIcon
														fontSize='large'
														sx={{ fontWeight: "bold", color: "white" }}
													/>
												</IconButton>
											</label>

											{loading ? (
												<Box sx={{ my: 2 }}>
													<CircularProgress className='textColor' />
												</Box>
											) : (
												<img
													src={imageLink2?.imageLink2}
													style={{ width: "200px", borderRadius: "50%" }}
													alt=''
												/>
											)}
										</Box>

										<TextField
											required
											sx={{ width: "100%", mb: 2 }}
											id='"outlined-multiline-flexible'
											label='Title'
											{...register("title", { required: true })}
										/>
										<TextField
											required
											sx={{ width: "100%", mb: 2 }}
											id='"outlined-multiline-flexible'
											label='Description'
											{...register("description", { required: true })}
										/>
										<TextField
											required
											sx={{ width: "100%", mb: 2 }}
											id='"outlined-multiline-flexible'
											label='Url'
											{...register("url", { required: true })}
										/>
										<Box className='icons z1'>
											<Button
												type='submit'
												variant='contained'
												sx={{
													px: 3,
													py: 1.5,
													width: "100%",
													fontWeight: "bold",
													borderRadius: "25px",
													border: "4px solid ",
												}}>
												Post Afiliate
											</Button>
										</Box>
									</form>
								</Grid>
							</Grid>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default AddAfiliates;
