import { Button, Container, Paper } from "@mui/material";
import React from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";
SwiperCore.use([Autoplay, Pagination]);

const Afiliates = () => {
	const { data } = useAPI();
	return (
		<Container
			sx={{ pt: 5, "& .swiper-slide": { height: "auto" }, cursor: "grab" }}
			className='reviews'>
			{data?._id ? (
				<>
					{data?.headline?.affiliate === "off" ? (
						""
					) : (
						<>
							<Box className='z2'>
								<Typography
									className=' bgColor borderColor'
									sx={{
										mb: 1.5,
										fontWeight: 900,
										color: "white",
										px: 3,
										py: 0.5,
										borderRadius: "25px",
										border: "4px solid",
										fontSize: "1.7rem",
									}}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.affiliate}
								</Typography>
							</Box>
							<Swiper
								loop={true}
								autoplay={{ delay: 2000 }}
								grabCursor={true}
								slidesPerView={3}
								spaceBetween={30}
								pagination={{
									clickable: true,
								}}
								breakpoints={{
									300: {
										slidesPerView: 1,
									},
									550: {
										slidesPerView: 2,
									},
									900: {
										slidesPerView: 3,
									},
								}}
								className='mySwiper'>
								{data?.allafiliates?.map((afiliate, key) => (
									<SwiperSlide key={key}>
										<Box
											className='borderColor'
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												p: 1.5,
												border: "4px solid ",
												bgcolor: "transparent",
												borderRadius: "25px",
												minHeight: "200px",
												justifyContent: "space-between",
											}}
											as={Paper}>
											<img
												style={{ width: "81px", height: "81px" }}
												src={afiliate?.logo}
												alt=''
											/>
											<Typography
												component='div'
												variant='h5'
												className='textColor'
												sx={{ fontWeight: "bold" }}>
												{afiliate?.title}
											</Typography>
											<Typography
												component='div'
												variant='subtitle'
												className='textColor'
												sx={{ fontWeight: "bold", py: 1 }}>
												{afiliate?.description}
											</Typography>

											<Button
												onClick={() =>
													window.open(`${afiliate?.url}`, "_blank")
												}
												variant='contained'
												sx={{
													fontWeight: "bold",
													borderRadius: "25px",
													border: "4px solid ",
												}}>
												Visit Website
											</Button>
										</Box>
									</SwiperSlide>
								))}
							</Swiper>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};
export default Afiliates;
