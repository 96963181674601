import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";

const CopyRight = () => {
	const { data } = useAPI();
	return (
		<div style={{ border: "4px solid" }} className='borderColor'>
			{data?._id ? (
				<>
					<img
						src={data?.details?.logo}
						alt=''
						style={{ width: "100%", height: "100%" }}
					/>
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</div>
	);
};

export default CopyRight;
