import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Box,
	Select,
	MenuItem,
	Checkbox,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import Banner from "../Banner/Banner";

const Event = () => {
	const { id } = useParams();
	const [data, setData] = useState();
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/singleEvent/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, [id]);
	const form = useRef();
	const { register, handleSubmit, reset } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const onSubmit = (datas) => {
		const datass = {
			id: `refferel${Math.floor(100000 + Math.random() * 900000)}`,
			...datas,
			eventId: data?.eventId,
			submitTime: new Date().toLocaleString(),
		};
		setSubmitting(true);
		axios
			.post(` https://soldbytm.onrender.com/EventsRegs`, datass)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your Registration has been submitted.",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const [selectState, setSelectState] = useState("");
	const state = [
		{ name: "Alabama", abbreviation: "AL" },
		{ name: "Alaska", abbreviation: "AK" },
		{ name: "Arizona", abbreviation: "AZ" },
		{ name: "Arkansas", abbreviation: "AR" },
		{ name: "California", abbreviation: "CA" },
		{ name: "Colorado", abbreviation: "CO" },
		{ name: "Connecticut", abbreviation: "CT" },
		{ name: "Delaware", abbreviation: "DE" },
		{ name: "Florida", abbreviation: "FL" },
		{ name: "Georgia", abbreviation: "GA" },
		{ name: "Hawaii", abbreviation: "HI" },
		{ name: "Idaho", abbreviation: "ID" },
		{ name: "Illinois", abbreviation: "IL" },
		{ name: "Indiana", abbreviation: "IN" },
		{ name: "Iowa", abbreviation: "IA" },
		{ name: "Kansas", abbreviation: "KS" },
		{ name: "Kentucky", abbreviation: "KY" },
		{ name: "Louisiana", abbreviation: "LA" },
		{ name: "Maine", abbreviation: "ME" },
		{ name: "Maryland", abbreviation: "MD" },
		{ name: "Massachusetts", abbreviation: "MA" },
		{ name: "Michigan", abbreviation: "MI" },
		{ name: "Minnesota", abbreviation: "MN" },
		{ name: "Mississippi", abbreviation: "MS" },
		{ name: "Missouri", abbreviation: "MO" },
		{ name: "Montana", abbreviation: "MT" },
		{ name: "Nebraska", abbreviation: "NE" },
		{ name: "Nevada", abbreviation: "NV" },
		{ name: "New Hampshire", abbreviation: "NH" },
		{ name: "New Jersey", abbreviation: "NJ" },
		{ name: "New Mexico", abbreviation: "NM" },
		{ name: "New York", abbreviation: "NY" },
		{ name: "North Carolina", abbreviation: "NC" },
		{ name: "North Dakota", abbreviation: "ND" },
		{ name: "Ohio", abbreviation: "OH" },
		{ name: "Oklahoma", abbreviation: "OK" },
		{ name: "Oregon", abbreviation: "OR" },
		{ name: "Pennsylvania", abbreviation: "PA" },
		{ name: "Rhode Island", abbreviation: "RI" },
		{ name: "South Carolina", abbreviation: "SC" },
		{ name: "South Dakota", abbreviation: "SD" },
		{ name: "Tennessee", abbreviation: "TN" },
		{ name: "Texas", abbreviation: "TX" },
		{ name: "Utah", abbreviation: "UT" },
		{ name: "Vermont", abbreviation: "VT" },
		{ name: "Virginia", abbreviation: "VA" },
		{ name: "Washington", abbreviation: "WA" },
		{ name: "West Virginia", abbreviation: "WV" },
		{ name: "Wisconsin", abbreviation: "WI" },
		{ name: "Wyoming", abbreviation: "WY" },
	];
	const [num, setNum] = useState();

	return (
		<Container
			sx={{
				pb: 5,
				"& .MuiCheckbox-root": {
					p: 0,
					backgroundColor: "transparent !important",
				},
			}}
			className='contact'>
			{data?._id ? (
				<>
					<Banner />
					<Typography>
						<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
							<Grid container spacing={2} sx={{ mb: 2 }}>
								<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
									<ReactPlayer
										key={data?.videoLink}
										className='borderColor react-player'
										width='100%'
										url={data?.videoLink}
										controls
									/>
								</Grid>
								<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
									<img
										style={{ width: "100%", height: "100%" }}
										src={data?.image}
										alt=''
									/>
								</Grid>
								<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h6'
										component='div'>
										{data?.title || "N/A"}
									</Typography>
									<Typography className='textColor' variant='p' component='div'>
										{data?.date || "N/A"}
									</Typography>
									<Typography className='textColor' variant='p' component='div'>
										{data?.time || "N/A"}
									</Typography>
									<Typography className='textColor' variant='p' component='div'>
										At{" "}
										{`${data?.address}, ${data?.city}, ${data?.state}, ${data?.zip}`}
									</Typography>
								</Grid>
								<Grid item md={6} xs={12}>
									<Grid container spacing={2}>
										<Grid item md={10} xs={12} sx={{ mx: "auto" }}>
											<Box className='z3'>
												<Typography
													className=' bgColor borderColor'
													sx={{
														fontWeight: 900,
														color: "white",
														px: 3,
														py: 0.5,
														borderRadius: "25px",
														border: "2px solid",
													}}
													variant='h6'
													component='div'
													gutterBottom>
													Register for this
												</Typography>
											</Box>
										</Grid>
										<Grid item md={12} xs={12}>
											<TextField
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='UserName'
												label='Full Name'
												placeholder='Ex: John Doe'
												type='text'
												inputProps={{
													minLength: 5,
												}}
												{...register("userName", { required: true })}
											/>
										</Grid>

										<Grid item md={12} xs={12}>
											<TextField
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='address'
												label='Address'
												type='text'
												inputProps={{
													minLength: 10,
													pattern: "^(?=.*[0-9]{3,})([a-zA-Z0-9_/+*.- ]{6,})$",
												}}
												placeholder='Ex: 3201 Liberty Street'
												{...register("address")}
											/>
										</Grid>
										<Grid item md={12} xs={12}>
											<TextField
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='city'
												type='text'
												label='Area/City'
												inputProps={{
													minLength: 3,
												}}
												placeholder='Ex: Los Angeles'
												{...register("city", { required: true })}
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<TextField
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='zip'
												type='text'
												label='Zip Code'
												inputProps={{
													minLength: 3,
												}}
												placeholder='Ex: 90001'
												{...register("zip", { required: true })}
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<FormControl fullWidth>
												<InputLabel id='demo-simple-select-label'>
													State*
												</InputLabel>
												<Select
													required
													labelId='demo-simple-select-label'
													id='demo-simple-select'
													value={selectState}
													{...register("state", {
														required: true,
													})}
													onChange={(e) => setSelectState(e.target.value)}>
													{state?.map((s) => (
														<MenuItem value={s?.abbreviation}>
															{s?.abbreviation}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item md={12} xs={12}>
											<TextField
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='PhoneNumber'
												label='Phone Number'
												type='text'
												inputProps={{
													minLength: 10,
												}}
												placeholder='Ex: (310) 777-7777'
												value={num}
												onInput={(e) =>
													setNum(e.target.value.replace(/[^0-9]/g, ""))
												}
												{...register("phoneNumber", {
													required: true,
												})}
											/>
										</Grid>
										<Grid item md={12} xs={12}>
											<TextField
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='UserEmail'
												type='email'
												label='E-mail'
												placeholder='Ex: john@email.com'
												inputProps={{
													pattern:
														"[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
												}}
												{...register("userEmail", { required: true })}
											/>
										</Grid>

										<Grid item md={12} xs={12}>
											<TextField
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='time'
												label='Best time to Reach you'
												placeholder='Ex: 25 Sep 2021 10:00 AM - 12:00 PM'
												type='text'
												inputProps={{
													minLength: 5,
												}}
												{...register("time", { required: true })}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item md={6} xs={12}>
									<Grid
										container
										spacing={2}
										sx={{
											textAlign: "left",
											justifyContent: "center",
										}}>
										<Grid item md={10} xs={12} sx={{ mx: "auto" }}>
											<Box className='z3'>
												<Typography
													className=' bgColor borderColor'
													sx={{
														fontWeight: 900,
														color: "white",
														px: 3,
														py: 0.5,
														borderRadius: "25px",
														border: "2px solid",
													}}
													variant='h6'
													component='div'
													gutterBottom>
													What your current Real Estate needs?
												</Typography>
											</Box>
										</Grid>

										<Grid item md={10} xs={12} sx={{ mx: "auto" }}></Grid>
										<Grid item md={10} xs={12} className='textColor'>
											<Checkbox
												size='large'
												className='textColor'
												type='checkbox'
												{...register("lookingToBuy")}
											/>{" "}
											Looking to buy now or very soon.
										</Grid>
										<Grid item md={10} xs={12} className='textColor'>
											<Checkbox
												size='large'
												className='textColor'
												type='checkbox'
												{...register("planningToBuy")}
											/>{" "}
											Planning to buy within a year.
										</Grid>
										<Grid item md={10} xs={12} className='textColor'>
											<Checkbox
												size='large'
												className='textColor'
												type='checkbox'
												{...register("lookingToSell")}
											/>{" "}
											Looking to sell/checking on prices.
										</Grid>
										<Grid item md={10} xs={12} className='textColor'>
											<Checkbox
												size='large'
												className='textColor'
												type='checkbox'
												{...register("justShopping")}
											/>{" "}
											Just shopping.
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Box className='icons z1'>
								<Button
									className='borderColor'
									type='submit'
									variant='contained'
									sx={{
										px: 5,
										py: 0.5,
										fontWeight: "bold",
										borderRadius: "25px",
										border: "4px solid ",
										fontSize: "1rem",
									}}>
									Submit
								</Button>
							</Box>
						</form>
					</Typography>
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Event;
