import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import AddAfiliates from "./AddAfiliates";

const AfiliatesManage = () => {
	const id = "62e6d54ef72dff2efb4be231";
	const [Afiliates, setAfiliates] = useState();
	const [deleted, setDeleted] = useState(false);
	const [open, setOpen] = useState(false);
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/userDB/${id}`)
			.then((res) => res.json())
			.then((data) => setAfiliates(data?.allafiliates?.reverse()));
	}, [deleted, open]);

	const handleDelete = (deletedAfiliate) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(
						`https://soldbytm.onrender.com/deleteAfiliate/${id}/${deletedAfiliate?.afiliateId}`,
						{
							public_id: deletedAfiliate?.public_id,
						},
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That Afiliate has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	let count = 1;
	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }} className='reviews'>
			{open && <AddAfiliates open={open} setOpen={setOpen} />}
			<Grid>
				<Typography
					variant='h4'
					className='textColor'
					sx={{ fontWeight: "bold" }}>
					All Afiliates
				</Typography>
				<Typography sx={{ pb: 2 }} className='textColor'>
					Last changed in {Afiliates?.[0]?.submitTime || "N/A"}
				</Typography>
				<Button
					onClick={() => setOpen(true)}
					variant='contained'
					sx={{
						mb: 1,
						px: 3,
						py: 1.5,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "4px solid ",
					}}>
					Post Afiliate
				</Button>
				<Grid item xs={12} md={12}>
					<Paper
						className='container'
						sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
						<Table size='small' aria-label='a dense table'>
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>No</TableCell>
									<TableCell align='left'>Logo</TableCell>
									<TableCell align='left'>Title</TableCell>
									<TableCell align='left'>Link</TableCell>
									<TableCell align='left'>Posted Time</TableCell>
									<TableCell align='center'>Action</TableCell>
								</TableRow>
							</TableHead>
							{Afiliates?.length > 0 ? (
								<TableBody sx={{ td: { py: 1 } }}>
									{Afiliates.map((afiliate, key) => (
										<TableRow
											key={key}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											<TableCell align='left'>{count++}</TableCell>
											<TableCell>
												<img
													src={afiliate?.logo || "N/A"}
													alt=''
													width='50px'
													height='50px'
													style={{ borderRadius: "50%" }}
												/>
											</TableCell>
											<TableCell align='left'>
												{afiliate?.title || "N/A"}
											</TableCell>
											<TableCell align='left'>
												<Button
													onClick={() =>
														window.open(`${afiliate?.url}`, "_blank")
													}
													variant='contained'
													sx={{
														fontWeight: "bold",
														borderRadius: "25px",
														border: "4px solid ",
													}}>
													Visit Link
												</Button>
											</TableCell>
											<TableCell align='left'>
												{afiliate?.submitTime || "N/A"}
											</TableCell>
											<TableCell align='center'>
												<Button
													className='button border'
													onClick={() => handleDelete(afiliate)}
													sx={{
														fontWeight: "bold",
														border: "4px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
													}}
													variant='contained'>
													<DeleteIcon />
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
									</TableRow>
								</TableHead>
							)}
						</Table>
					</Paper>
				</Grid>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!Afiliates}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default AfiliatesManage;
