import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Box,
	Select,
	MenuItem,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Refferel = () => {
	const { data } = useAPI();
	const form = useRef();
	const { register, handleSubmit, reset } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const [reason, setReason] = useState("");
	const onSubmit = (datas) => {
		const data = {
			id: `refferel${Math.floor(100000 + Math.random() * 900000)}`,
			...datas,
			submitTime: new Date().toLocaleString(),
		};
		setSubmitting(true);
		console.log(data);
		axios
			.post(` https://soldbytm.onrender.com/refferel`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your Referral Sent Successfully",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const [selectState, setSelectState] = useState("");
	const state = [
		{ name: "Alabama", abbreviation: "AL" },
		{ name: "Alaska", abbreviation: "AK" },
		{ name: "Arizona", abbreviation: "AZ" },
		{ name: "Arkansas", abbreviation: "AR" },
		{ name: "California", abbreviation: "CA" },
		{ name: "Colorado", abbreviation: "CO" },
		{ name: "Connecticut", abbreviation: "CT" },
		{ name: "Delaware", abbreviation: "DE" },
		{ name: "Florida", abbreviation: "FL" },
		{ name: "Georgia", abbreviation: "GA" },
		{ name: "Hawaii", abbreviation: "HI" },
		{ name: "Idaho", abbreviation: "ID" },
		{ name: "Illinois", abbreviation: "IL" },
		{ name: "Indiana", abbreviation: "IN" },
		{ name: "Iowa", abbreviation: "IA" },
		{ name: "Kansas", abbreviation: "KS" },
		{ name: "Kentucky", abbreviation: "KY" },
		{ name: "Louisiana", abbreviation: "LA" },
		{ name: "Maine", abbreviation: "ME" },
		{ name: "Maryland", abbreviation: "MD" },
		{ name: "Massachusetts", abbreviation: "MA" },
		{ name: "Michigan", abbreviation: "MI" },
		{ name: "Minnesota", abbreviation: "MN" },
		{ name: "Mississippi", abbreviation: "MS" },
		{ name: "Missouri", abbreviation: "MO" },
		{ name: "Montana", abbreviation: "MT" },
		{ name: "Nebraska", abbreviation: "NE" },
		{ name: "Nevada", abbreviation: "NV" },
		{ name: "New Hampshire", abbreviation: "NH" },
		{ name: "New Jersey", abbreviation: "NJ" },
		{ name: "New Mexico", abbreviation: "NM" },
		{ name: "New York", abbreviation: "NY" },
		{ name: "North Carolina", abbreviation: "NC" },
		{ name: "North Dakota", abbreviation: "ND" },
		{ name: "Ohio", abbreviation: "OH" },
		{ name: "Oklahoma", abbreviation: "OK" },
		{ name: "Oregon", abbreviation: "OR" },
		{ name: "Pennsylvania", abbreviation: "PA" },
		{ name: "Rhode Island", abbreviation: "RI" },
		{ name: "South Carolina", abbreviation: "SC" },
		{ name: "South Dakota", abbreviation: "SD" },
		{ name: "Tennessee", abbreviation: "TN" },
		{ name: "Texas", abbreviation: "TX" },
		{ name: "Utah", abbreviation: "UT" },
		{ name: "Vermont", abbreviation: "VT" },
		{ name: "Virginia", abbreviation: "VA" },
		{ name: "Washington", abbreviation: "WA" },
		{ name: "West Virginia", abbreviation: "WV" },
		{ name: "Wisconsin", abbreviation: "WI" },
		{ name: "Wyoming", abbreviation: "WY" },
	];
	const [num, setNum] = useState();
	const [num1, setNum1] = useState();
	return (
		<Container sx={{ pt: 5 }} className='contact'>
			{data?._id ? (
				<>
					{!data?.headline?.refferel === "off" ? (
						""
					) : (
						<>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Box className='z4'>
									<Typography
										className=' bgColor borderColor'
										sx={{
											mb: 1.5,
											fontWeight: 900,
											color: "white",
											px: 3,
											py: 0.5,
											borderRadius: "25px",
											border: "4px solid",
										}}
										variant='h4'
										component='div'
										gutterBottom>
										{data?.headline?.refferel}
									</Typography>
								</Box>
							</Box>
							<Typography>
								<form
									ref={form}
									onSubmit={handleSubmit(onSubmit)}
									method='post'>
									<Grid container spacing={2} sx={{ mb: 2 }}>
										<Grid item md={6} xs={12}>
											<Grid container spacing={2}>
												<Grid item md={10} xs={12} sx={{ mx: "auto" }}>
													<Box className='z3'>
														<Typography
															className=' bgColor borderColor'
															sx={{
																fontWeight: 900,
																color: "white",
																px: 3,
																py: 0.5,
																borderRadius: "25px",
																border: "2px solid",
															}}
															variant='h6'
															component='div'
															gutterBottom>
															Referred By :
														</Typography>
													</Box>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														required
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='UserName'
														label='Full Name'
														placeholder='Ex: John Doe'
														type='text'
														inputProps={{
															minLength: 5,
														}}
														{...register("refByuserName", { required: true })}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='businessName'
														label='Business Name (If any)'
														type='text'
														inputProps={{
															minLength: 5,
														}}
														placeholder='Enter Your Business Name'
														{...register("refBybusinessName")}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='businessAddress'
														label='Business Address'
														type='text'
														inputProps={{
															minLength: 10,
															pattern:
																"^(?=.*[0-9]{3,})([a-zA-Z0-9_/+*.- ]{6,})$",
														}}
														placeholder='Ex: 3201 Liberty Street'
														{...register("refBybusinessAddress")}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														required
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='PhoneNumber'
														label='Phone Number'
														type='text'
														inputProps={{
															minLength: 10,
														}}
														placeholder='Ex: (310) 777-7777'
														value={num}
														onInput={(e) =>
															setNum(e.target.value.replace(/[^0-9]/g, ""))
														}
														{...register("refByphoneNumber", {
															required: true,
														})}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														required
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='UserEmail'
														type='email'
														label='E-mail'
														placeholder='Ex: john@email.com'
														inputProps={{
															pattern:
																"[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
														}}
														{...register("refByuserEmail", { required: true })}
													/>
												</Grid>

												<Grid item md={12} xs={12}>
													<TextField
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='website'
														label='Website (If any)'
														placeholder='Ex: www.johndoe.com'
														type='text'
														inputProps={{
															minLength: 5,
														}}
														{...register("refBywebsite")}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Grid item md={6} xs={12}>
											<Grid container spacing={2}>
												<Grid item md={10} xs={12} sx={{ mx: "auto" }}>
													<Box className='z2'>
														<Typography
															className=' bgColor borderColor'
															sx={{
																fontWeight: 900,
																color: "white",
																px: 3,
																py: 0.5,
																borderRadius: "25px",
																border: "2px solid",
															}}
															variant='h6'
															component='div'
															gutterBottom>
															Referred (Potential) Clients Info :
														</Typography>
													</Box>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														required
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='UserName'
														label='Full Name'
														type='text'
														inputProps={{
															minLength: 5,
														}}
														placeholder='Ex: John Doe'
														{...register("refCliuserName", { required: true })}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='address'
														label='Address (If applicable)'
														type='text'
														inputProps={{
															minLength: 10,
															pattern:
																"^(?=.*[0-9]{3,})([a-zA-Z0-9_/+*.- ]{6,})$",
														}}
														placeholder='Ex: 3201 Liberty Street'
														{...register("refCliaddress")}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														required
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='PhoneNumber'
														label='Phone Number'
														type='text'
														inputProps={{
															minLength: 10,
														}}
														placeholder='Ex: (310) 777-7777'
														value={num1}
														onInput={(e) =>
															setNum1(e.target.value.replace(/[^0-9]/g, ""))
														}
														{...register("refCliphoneNumber", {
															required: true,
														})}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='UserEmail'
														type='email'
														label='E-mail (If any)'
														placeholder='Ex: john@email.com'
														inputProps={{
															pattern:
																"[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
														}}
														{...register("refCliuserEmail")}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<FormControl fullWidth>
														<InputLabel id='demo-simple-select-label'>
															Buy, Sell or Invest ?*
														</InputLabel>
														<Select
															required
															labelId='demo-simple-select-label'
															id='demo-simple-select'
															value={reason}
															{...register("refClireason", { required: true })}
															onChange={(e) => setReason(e.target.value)}>
															<MenuItem value={"Buy"}>Buy</MenuItem>
															<MenuItem value={"Sell"}>Sell</MenuItem>
															<MenuItem value={"Invest"}>Invest</MenuItem>
														</Select>
													</FormControl>
												</Grid>
												<Grid item md={8} xs={12}>
													<TextField
														required
														sx={{ width: "100%" }}
														id='outlined-basic'
														name='refCliuserCity'
														type='text'
														label='Area/City'
														inputProps={{
															minLength: 3,
														}}
														placeholder='Ex: Los Angeles'
														{...register("refCliuserCity", { required: true })}
													/>
												</Grid>
												<Grid item md={4} xs={12}>
													<FormControl fullWidth>
														<InputLabel id='demo-simple-select-label'>
															State*
														</InputLabel>
														<Select
															required
															labelId='demo-simple-select-label'
															id='demo-simple-select'
															value={selectState}
															{...register("refCliuserState", {
																required: true,
															})}
															onChange={(e) => setSelectState(e.target.value)}>
															{state?.map((s) => (
																<MenuItem value={s?.abbreviation}>
																	{s?.abbreviation}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
										<Grid item md={12} xs={12}>
											<Accordion className='z3' defaultExpanded>
												<AccordionSummary
													className='border borderColor'
													sx={{
														textAlign: "center",
														border: "1px solid",
													}}
													expandIcon={<ExpandMoreIcon />}
													aria-controls='panel1a-content'
													id='panel1a-header'>
													<Typography
														sx={{
															fontWeight: 500,
															borderRadius: "25px",
															mb: 1,
														}}>
														BUSINESS OPPORTUNITY! PLEASE READ!
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<Typography
														className='textColor'
														sx={{ whiteSpace: "pre-line" }}>
														I am delighted to introduce a SPECIAL OPPORTUNITY
														that not only values your referrals but rewards your
														business with exclusive benefits. If you know
														someone looking to BUY, SELL, or INVEST in real
														estate within the areas I specialize, your referrals
														can now translate into significant advantages for
														your business. Upon the successful completion of a
														referred transaction, I am excited to extend to you
														a complimentary advertisement of your business
														across all my social media platforms for FREE, for
														an entire 3 months per referral. This is a powerful
														strategy to boost your visibility and connect with a
														broader audience. Your trust is our top priority. Be
														assured that any information shared will be treated
														with the utmost confidentiality. Your privacy is
														non-negotiable, and I am committed to not disclosing
														any personal details without your explicit consent,
														except when required by law. Your referrals are the
														heartbeat of my business, and I am truly grateful
														for your trust. The highest compliment I can receive
														is the referral of your friends, family, and
														business associates. It&#39;s a testament to our
														strong partnership. Initiating this rewarding
														experience is simple. Just have your referrals
														mention your name as the source of referral when
														they connect with me, or provide their contact
														information directly. I will handle the rest,
														ensuring a seamless and professional experience for
														everyone involved. Thank you for your ongoing trust
														and collaboration. I am dedicated to making this a
														rewarding experience for you and your business.
													</Typography>
												</AccordionDetails>
											</Accordion>
										</Grid>
									</Grid>
									<Box className='icons z1'>
										<Button
											className='borderColor'
											type='submit'
											variant='contained'
											sx={{
												px: 3,
												py: 0.5,
												width: "100%",
												fontWeight: "bold",
												borderRadius: "25px",
												border: "4px solid ",
												fontSize: "1rem",
											}}>
											Submit
										</Button>
									</Box>
								</form>
							</Typography>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Refferel;
