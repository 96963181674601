import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	IconButton,
	Backdrop,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
import ReactPlayer from "react-player";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	maxHeight: "90vh",
	width: "100%",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const EditEvent = ({ open, setOpen }) => {
	const form = useRef();
	const [submitting, setSubmitting] = useState(false);
	const [defaults, setDefaults] = useState(open?.default);
	const [imageLink2, setImageLink2] = useState(open?.image);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			...open,
		},
	});
	useEffect(() => {
		setDefaults(open?.default);
	}, [open]);

	const onSubmit = ({
		videoLink,
		title,
		address,
		city,
		zip,
		state,
		time,
		date,
	}) => {
		const datas = {
			eventId: open?.eventId,
			image: imageLink2?.imageLink2 || open?.image,
			public_id: imageLink2?.public_id || open?.public_id,
			old_public_id: open?.public_id,
			videoLink,
			title,
			address,
			city,
			zip,
			state,
			time,
			date,
			default: defaults,
			submitTime: open?.submitTime,
		};

		setSubmitting(true);
		axios
			.put(`https://soldbytm.onrender.com/Events/${open?._id}`, datas)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your Event has been updated!",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
				setOpen(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const [videoLink, setVideoLink] = useState("");
	useEffect(() => {
		setVideoLink(open?.videoLink);
		setSelectState(open?.state);
	}, [open]);
	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "DBC-Events");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dbc-of-tony/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2({
			imageLink2: file.secure_url,
			public_id: file.public_id,
		});
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	const [selectState, setSelectState] = useState("");
	const state = [
		{ name: "Alabama", abbreviation: "AL" },
		{ name: "Alaska", abbreviation: "AK" },
		{ name: "Arizona", abbreviation: "AZ" },
		{ name: "Arkansas", abbreviation: "AR" },
		{ name: "California", abbreviation: "CA" },
		{ name: "Colorado", abbreviation: "CO" },
		{ name: "Connecticut", abbreviation: "CT" },
		{ name: "Delaware", abbreviation: "DE" },
		{ name: "Florida", abbreviation: "FL" },
		{ name: "Georgia", abbreviation: "GA" },
		{ name: "Hawaii", abbreviation: "HI" },
		{ name: "Idaho", abbreviation: "ID" },
		{ name: "Illinois", abbreviation: "IL" },
		{ name: "Indiana", abbreviation: "IN" },
		{ name: "Iowa", abbreviation: "IA" },
		{ name: "Kansas", abbreviation: "KS" },
		{ name: "Kentucky", abbreviation: "KY" },
		{ name: "Louisiana", abbreviation: "LA" },
		{ name: "Maine", abbreviation: "ME" },
		{ name: "Maryland", abbreviation: "MD" },
		{ name: "Massachusetts", abbreviation: "MA" },
		{ name: "Michigan", abbreviation: "MI" },
		{ name: "Minnesota", abbreviation: "MN" },
		{ name: "Mississippi", abbreviation: "MS" },
		{ name: "Missouri", abbreviation: "MO" },
		{ name: "Montana", abbreviation: "MT" },
		{ name: "Nebraska", abbreviation: "NE" },
		{ name: "Nevada", abbreviation: "NV" },
		{ name: "New Hampshire", abbreviation: "NH" },
		{ name: "New Jersey", abbreviation: "NJ" },
		{ name: "New Mexico", abbreviation: "NM" },
		{ name: "New York", abbreviation: "NY" },
		{ name: "North Carolina", abbreviation: "NC" },
		{ name: "North Dakota", abbreviation: "ND" },
		{ name: "Ohio", abbreviation: "OH" },
		{ name: "Oklahoma", abbreviation: "OK" },
		{ name: "Oregon", abbreviation: "OR" },
		{ name: "Pennsylvania", abbreviation: "PA" },
		{ name: "Rhode Island", abbreviation: "RI" },
		{ name: "South Carolina", abbreviation: "SC" },
		{ name: "South Dakota", abbreviation: "SD" },
		{ name: "Tennessee", abbreviation: "TN" },
		{ name: "Texas", abbreviation: "TX" },
		{ name: "Utah", abbreviation: "UT" },
		{ name: "Vermont", abbreviation: "VT" },
		{ name: "Virginia", abbreviation: "VA" },
		{ name: "Washington", abbreviation: "WA" },
		{ name: "West Virginia", abbreviation: "WV" },
		{ name: "Wisconsin", abbreviation: "WI" },
		{ name: "Wyoming", abbreviation: "WY" },
	];
	return (
		<div className='reviews'>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<Box
						sx={style}
						style={{
							backgroundImage: "url('/bg-img.jpg')",
						}}>
						<CancelIcon
							onClick={() => setOpen(false)}
							className='textColor'
							sx={{
								position: "fixed",
								top: "5px",
								right: "5px",
								cursor: "pointer",
							}}
						/>
						<Typography
							className=' bgColor'
							sx={{
								mb: 1.5,
								fontWeight: 900,
								color: "white",
								px: 3,
								py: 0.5,
								borderRadius: "25px",
								border: "4px solid",
								textAlign: "center",
							}}
							variant='h4'
							component='div'
							gutterBottom>
							Update Event
						</Typography>

						<Grid container spacing={2}>
							<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
								<form ref={form} onSubmit={handleSubmit(onSubmit)}>
									<Grid container spacing={2}>
										<Grid item md={6} xs={12}>
											<Box
												display='flex'
												flexDirection='column'
												alignItems='center'
												sx={{ mx: "auto" }}>
												<label
													className='bgColor'
													htmlFor='icon-button-file'
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														margin: "0 9px",
														cursor: "pointer",
														minWidth: "100%",
														px: 3,
														p: 1.5,
														border: "4px solid white",
														borderRadius: "15px",
													}}>
													<Input
														accept='image/*'
														id='icon-button-file'
														type='file'
														onChange={uploadImage}
													/>
													<Typography
														sx={{ my: 2, ml: 2, color: "white" }}
														variant='button'
														component='div'
														gutterBottom>
														Upload Picture
													</Typography>
													<IconButton
														sx={{ mx: 2 }}
														aria-label='upload picture'
														component='span'>
														<AccountCircleIcon
															fontSize='large'
															sx={{ fontWeight: "bold", color: "white" }}
														/>
													</IconButton>
												</label>

												{loading ? (
													<Box sx={{ my: 2 }}>
														<CircularProgress className='textColor' />
													</Box>
												) : (
													<img
														src={imageLink2?.imageLink2 || open?.image}
														style={{ width: "100%", height: "100%" }}
														alt=''
													/>
												)}
											</Box>
										</Grid>
										<Grid item md={6} xs={12}>
											<Box>
												<TextField
													required
													sx={{ width: "100%", mb: 2 }}
													id='"outlined-multiline-flexible'
													label='Video Link'
													multiline
													rows={2}
													{...register("videoLink", { required: true })}
													onChange={(e) => setVideoLink(e.target.value)}
												/>
												<ReactPlayer
													className='borderColor react-player'
													width='100%'
													url={videoLink}
													controls
												/>
											</Box>
										</Grid>
										<Grid item md={12} xs={12}>
											<FormControl sx={{ my: 2 }}>
												<FormLabel
													id='demo-row-radio-buttons-group-label'
													className='textColor'>
													Default View
												</FormLabel>
												<RadioGroup
													row
													aria-labelledby='demo-row-radio-buttons-group-label'
													name='row-radio-buttons-group'>
													<FormControlLabel
														className='textColor'
														value='photo'
														control={
															<Checkbox
																className='textColor'
																checked={defaults === "photo"}
																onChange={(e) => setDefaults(e.target.value)}
																sx={{
																	p: 0,
																	backgroundColor: "transparent",
																	mr: 1,
																}}
															/>
														}
														label='Photo'
													/>
													<FormControlLabel
														className='textColor'
														value='video'
														control={
															<Checkbox
																className='textColor'
																checked={defaults === "video"}
																onChange={(e) => setDefaults(e.target.value)}
																sx={{
																	p: 0,
																	backgroundColor: "transparent",
																	mr: 1,
																}}
															/>
														}
														label='Video'
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
										<Grid item md={12} xs={12}>
											<TextField
												required
												sx={{ width: "100%", mb: 2 }}
												id='"outlined-multiline-flexible'
												label='Title'
												{...register("title", { required: true })}
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<TextField
												required
												sx={{ width: "100%", mb: 2 }}
												id='"outlined-multiline-flexible'
												label='Date'
												{...register("date", { required: true })}
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<TextField
												required
												sx={{ width: "100%", mb: 2 }}
												id='"outlined-multiline-flexible'
												label='Time'
												{...register("time", { required: true })}
											/>
										</Grid>

										<Grid item md={12} xs={12}>
											<TextField
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='address'
												label='Address'
												type='text'
												inputProps={{
													minLength: 10,
													pattern: "^(?=.*[0-9]{3,})([a-zA-Z0-9_/+*.- ]{6,})$",
												}}
												placeholder='Ex: 3201 Liberty Street'
												{...register("address")}
											/>
										</Grid>
										<Grid item md={12} xs={12}>
											<TextField
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='city'
												type='text'
												label='Area/City'
												inputProps={{
													minLength: 3,
												}}
												placeholder='Ex: Los Angeles'
												{...register("city", { required: true })}
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<TextField
												required
												sx={{ width: "100%" }}
												id='outlined-basic'
												name='zip'
												type='text'
												label='Zip Code'
												inputProps={{
													minLength: 3,
												}}
												placeholder='Ex: 90001'
												{...register("zip", { required: true })}
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<FormControl fullWidth>
												<InputLabel id='demo-simple-select-label'>
													State*
												</InputLabel>
												<Select
													required
													labelId='demo-simple-select-label'
													id='demo-simple-select'
													value={selectState}
													defaultValue={open?.state}
													{...register("state", {
														required: true,
													})}
													onChange={(e) => setSelectState(e.target.value)}>
													{state?.map((s) => (
														<MenuItem value={s?.abbreviation}>
															{s?.abbreviation}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item md={12} xs={12}>
											<Box className='icons z1'>
												<Button
													type='submit'
													variant='contained'
													sx={{
														px: 3,
														py: 1.5,
														width: "100%",
														fontWeight: "bold",
														borderRadius: "25px",
														border: "4px solid ",
													}}>
													Update Event
												</Button>
											</Box>
										</Grid>
									</Grid>
								</form>
							</Grid>
						</Grid>
					</Box>
				</Fade>
			</Modal>

			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 999,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default EditEvent;
