import {
	Button,
	Card,
	CircularProgress,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Refferels = () => {
	const { id } = useParams();
	const [data, setData] = useState([]);
	useEffect(() => {
		fetch(`https://soldbytm.onrender.com/refferel/${id}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, [id]);

	return (
		<Container className='mails'>
			{data?.refByuserName ? (
				<Grid
					container
					spacing={0}
					direction='column'
					alignItems='center'
					justifyContent='center'
					style={{ minHeight: "85vh" }}>
					<Box>
						<Card
							className='borderColor'
							sx={{
								p: 2,
								my: 1,
								minWidth: "300px",
								display: "flex",
								flexDirection: "column",
								alignContent: "center",
								overflow: "visible",
								border: "4px solid ",
								textAlign: "left",
								borderRadius: 3,
							}}>
							<Typography
								variant='h5'
								className='textColor'
								sx={{ fontWeight: "bold" }}>
								Reffered By
							</Typography>
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>Full Name : </span>
								{data?.refByuserName}
							</Typography>
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}> Business Name : </span>
								{data?.refBybusinessName}
							</Typography>{" "}
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}> Business Address : </span>
								{data?.refBybusinessAddress}
							</Typography>{" "}
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>Phone Number : </span>
								<a href={`tel:${data?.refByphoneNumber}`}>
									{data?.refByphoneNumber}
								</a>
							</Typography>{" "}
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>E-mail : </span>
								<a href={`tel:${data?.refByuserEmail}`}>
									{data?.refByuserEmail}
								</a>
							</Typography>{" "}
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>Website : </span>
								{data?.refBywebsite}
							</Typography>{" "}
							<br />
							<Typography
								variant='h5'
								className='textColor'
								sx={{ fontWeight: "bold" }}>
								Reffered (Potential) Client
							</Typography>
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>Full Name : </span>
								{data?.refCliuserName}
							</Typography>{" "}
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}> Address : </span>
								{data?.refCliaddress}
							</Typography>{" "}
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>Phone Number : </span>
								<a href={`tel:${data?.refCliphoneNumber}`}>
									{data?.refCliphoneNumber}
								</a>
							</Typography>{" "}
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}> E-mail : </span>
								<a href={`tel:${data?.refCliuserEmail}`}>
									{data?.refCliuserEmail}
								</a>
							</Typography>
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>Reason : </span>
								{data?.refClireason}
							</Typography>
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>City : </span>
								{data?.refCliuserCity}
							</Typography>{" "}
							<Typography variant='h6' className='textColor'>
								<span style={{ fontWeight: "bold" }}>State : </span>
								{data?.refCliuserState}
							</Typography>{" "}
						</Card>
					</Box>
					<Link to='/dashboard/refferels' style={{ textDecoration: "none" }}>
						<Button
							variant='contained'
							className='button border'
							sx={{
								mt: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
								backgroundColor: "transparent",
								border: "4px solid",
							}}>
							<KeyboardBackspaceIcon sx={{ mr: 1.5 }} />
							Back to List
						</Button>
					</Link>
				</Grid>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CircularProgress className='textColor' />
				</div>
			)}
		</Container>
	);
};

export default Refferels;
